import React from 'react';
import { Link } from 'react-router-dom';

import Card from './Card/Card';
import CardEncerrados from './Card/CardEncerrados';
import {
  CampeonatosContainer,
  CampeonatosTitle,
  MobileCarouselWrapper,
  CardsAntigos,
} from './styles';
import campDados from '../../../../data/campDados.json';
import campDadosEncerrados from '../../../../data/campDadosEncerrados.json';
import '../areaCampeonatos/modal.css';
import { MobileCarouselWrapperAtual } from './styles';

const AreaCampeonatos = () => {
  return (
    <CampeonatosContainer>
      <CampeonatosTitle>Faça sua Inscrição</CampeonatosTitle>

      <MobileCarouselWrapperAtual>
        {campDados.map((item, index) => {
          return (
            <Link
              key={index}
              to={`/campeonato/${item.gamePath}`}
              style={{ textDecoration: 'none' }}>
              <Card
                key={index}
                gameId={item.gameId}
                championshipName={item.championshipName}
                championshipImg={item.championshipImg}
                gameImg={item.gameImg}
                date={item.date}
                inProgress={item.inProgress}
                isEnd={item.isEnd}
                currentUsers={item.currentUsers}
                maxUsers={item.maxUsers}
                squad={item.squad}
                result={item.result}
              />
            </Link>
          );
        })}
      </MobileCarouselWrapperAtual>

      {/* <CampeonatosTitle>Campeonatos encerrados</CampeonatosTitle> */}
      <MobileCarouselWrapper>
        {campDadosEncerrados.map((item, index) => {
          return (
            <CardsAntigos key={index}>
              <CardEncerrados
                gameId={item.gameId}
                championshipName={item.championshipName}
                championshipImg={item.championshipImg}
                gameImg={item.gameImg}
                date={item.date}
                inProgress={item.inProgress}
                isEnd={item.isEnd}
                currentUsers={item.currentUsers}
                maxUsers={item.maxUsers}
                squad={item.squad}
                result={item.result}
              />
            </CardsAntigos>
          );
        })}
      </MobileCarouselWrapper>
    </CampeonatosContainer>
  );
};

export default AreaCampeonatos;
