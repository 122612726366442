import FAQItem from './components/FAQItem';
import './FAQ.css';
import faqDados from '../../../../data/faqDados.json'

function FAQ() {
  return (
    <div className="faq">
        <h2>Perguntas Frequentes</h2>
      {
        faqDados.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))
      }
    </div >
  );
};

export default FAQ;