import { PoliticaContainer, PoliticaList } from './styled'

import Logo from 'assets/ativos/Logo 1.png';

import { HeaderLogo } from './styled';

import { Link } from "react-router-dom";

export default function Politica() {
  return (
    <PoliticaContainer>
       <Link to={`/`} style={{ textDecoration: 'none' }}>
          <HeaderLogo src={Logo} alt="GoGamers" />
          </Link>
      
      <h1>Política de Privacidade</h1>
      <br></br>
      <p>A GO GAMERS BRASIL EVENTOS ESPORTIVOS LTDA, com CNPJ nº 37.845.048/0001-00, se preocupa com a privacidade de seus membros e está comprometida com a proteção de todas as informações pessoais por eles disponibilizadas. A Go Gamers é plataforma online que presta serviços de gerenciamento de campeonato e outros tipos ligados aos seus membros, praticantes de esportes eletrônicos.</p>
      <br></br>
      <p>A Política de Privacidade (“Política”) da Go Gamers descreve como e quando o site irá utilizar as informações disponibilizadas pelos seus membros. Ao usar o nosso site, aplicativos e/ou contratar um serviço, o consumidor consente com a coleta, transferência, manipulação, armazenamento, divulgação e outros fins das suas informações como descrito na Política de Privacidade. Independentemente de sua nacionalidade ou país de residência, você autoriza a Go Gamers a usar as informações como previsto na Política. Se você possui qualquer dúvida ou comentário sobre a Política de Privacidade, favor entrar em contato pelo e-mail contato@gogamers.pro.</p>
      <br></br>
      <h2>DOS ENTENDIMENTOS PRELIMINARES:</h2>
      <br></br>
      <p>Para fins desta Política de Privacidade, aplicam-se os seguintes entendimentos:</p>
      <br></br>
      <PoliticaList>
        <li>“site”, “portal”, “plataforma”: designa o endereço eletrônico www.https://gogamers.pro, seus subdomínios, aplicativos e demais domínios direcionados ao portal Go Gamers.</li>
        <br></br>
        <li>“Membro”, “Membros”: pessoas físicas naturais devidamente registradas na Go Gamers.</li>
        <br></br>
        <li>“Cookies”: arquivos de texto muito pequenos, colocados no seu navegador por um servidor da Web, quando você visualiza alguns sites on-line (nem todos os sites criam cookies). Eles são usados para armazenar dados sobre você e suas preferências, de modo que um servidor da Web não precise solicitar repetidamente essas informações, o que pode melhorar o tempo de download.</li>
        <br></br>
        <li>“IP”: abreviatura de Internet Protocol. Conjunto de números que identifica o computador dos usuários na Internet.</li>
        </PoliticaList>
      <br></br>

      <h2>DA COLETA E USO DAS INFORMAÇÕES:</h2>
      <br></br>
      <p>Todas as informações dos nossos membros que coletamos em nosso site são de uso exclusivo da Go Gamers, sendo utilizadas para: prestação de serviços, manter os usuários a par de quaisquer atualizações relacionadas ao nosso site e às suas assinaturas, e enviar informações ou conteúdo nos quais você possa ter interesse. Também utilizamos essas informações para melhorar continuamente nossos serviços, nosso site e a sua experiência de compra. A base de dados formada pela Go Gamers por meio da coleta de informações no site, poderá ser compartilhada com nosso grupo de empresas e/ou parceiros de negócios.</p>
      <br></br>

      <h2>DAS INFORMAÇÕES COLETADAS NO CADASTRO:</h2>
      <br></br>
      <p>Para se tornar um membro da Go Gamers e ter acesso aos serviços é necessário realizar um cadastro conosco. Ao criar ou editar uma conta na Go Gamers, você deverá informar seus dados pessoais, incluindo nome, sobrenome, endereço residencial, endereço de e-mail e senha. A senha e suas informações deverão ser guardadas em um local seguro sob responsabilidade exclusiva do membro, pois sem essas informações não será possível acessar a sua conta. Você poderá ser responsabilizado pelo uso inapropriado e/ou não autorizado do nosso site, bem como ter suas informações privadas utilizadas por outras pessoas. Em algumas circunstâncias, tais como a realização de compra de nossos serviços, serão solicitados os dados de cartão de crédito ou de outra forma de pagamento aceitável, além do respectivo endereço de cobrança. Caso você se recuse a disponibilizar as informações necessárias para a compra, não poderemos fornecer nossos serviços e, consequentemente, você não poderá usufruir dos serviços da Go Gamers.</p>
      <br></br>

      <h2>DAS INFORMAÇÕES COLETADAS AUTOMATICAMENTE:</h2>
      <br></br>
      <p>Quando você visitar nosso site e/ou se registrar como um membro da Go Gamers nós podemos coletar determinadas informações por meios automatizados, tais como: cookies, web beacons e outros dispositivos automatizados. Um "cookie" é um arquivo de texto enviados pelos sites ao computador de um visitante ou a outro dispositivo conectado à Internet, para identificar exclusivamente o navegador do visitante ou para armazenar informações ou configurações no navegador. O "web beacon", também conhecido como um tag Internet, tag pixel ou GIF claro, é usado para transmitir informações de volta para um servidor web. Nós também podemos usar ferramentas de análise de sites fornecidos por terceiros (como Google Analytics e Facebook Insights), que coletam informações sobre o tráfego de visitantes.</p>
      <br></br>
      <p>As informações que podemos recolher por meios automatizados incluem: informações sobre os dispositivos que nossos visitantes usam para acessar a Internet (como o endereço de IP e o dispositivo, navegador, nomes de domínio e tipo de sistema operacional); URLs que direcionam visitantes à nossa página, datas e horários de visita ao nosso site; localização geográfica (como país e cidade) dos visitantes; informações sobre as ações realizadas em nosso site (como número de visualizações da página e padrões de navegação do site); os termos de pesquisa que os visitantes utilizaram para atingir nosso site e a página que o levou para nosso site.</p>
      <br></br>

      <h2>DAS OUTRAS TECNOLOGIAS DE RASTREAMENTO E ARMAZENAMENTO</h2>
      <br></br>
      <p>A Go Gamers possui servidores que rastreiam e gravam automaticamente as informações (“Log Data”, “Registro de Dados”) geradas por você quando navega pelo site e utiliza nossos serviços. Outras tecnologias de rastreamento e registro de dados serão utilizadas pela Go Gamers, tais como cookies, Flash Local Storage Objects (“Flash LSOs”), Clear Gifs (“Web Beacons”). As informações armazenadas em nossos servidores podem incluir desde o seu endereço de IP, tipo de navegador, sistema operacional, páginas visitadas, localização, sua operadora móvel, dispositivos e aplicações IDs, termos de pesquisa, informações de cookies, dentre outras. Todos os registros de dados (“Log Data”) serão gerados quando você interagir com nossos serviços, acessar nossos e-mails ou site de terceiros que possuem links ou banners da Go Gamers. O objetivo do rastreamento e armazenamento dessas informações é de aperfeiçoar e melhorar os serviços prestados por nós.</p>
      <br></br>

      <h2>DO ARMAZENAMENTO E PROTEÇÃO DAS INFORMAÇÕES</h2>
      <br></br>
      <p>A Go Gamers está comprometida com a segurança de todas as informações que recebemos de você. A GO GAMERS segue as melhores técnicas de gestão para proteger a confidencialidade, segurança e integridade dos dados armazenados em nosso sistema, no entanto não é possível atestar que qualquer sistema seja completamente seguro contra invasões. A GO GAMERS se exime de qualquer responsabilidade por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões do banco de dados e/ou servidores do site, salvo os casos em que houver comprovação de dolo ou culpa em nossa conduta. Todas as informações coletadas serão de uso exclusivo da GO GAMERS e somente poderão ser acessadas por pessoas qualificadas e previamente autorizadas pela GO GAMERS.</p>
      <br></br>
      <p>Caso o membro deixe de utilizar os serviços oferecidos pela Go Gamers, nós poderemos, para fins de auditoria e preservação de direitos, permanecer com o registro de dados e informações do cliente, pelo período máximo de 5 (cinco) anos, possuindo a faculdade de excluí-los definitivamente, segundo sua conveniência, após o prazo legal de guarda obrigatória de registro de 6 (seis) meses. O usuário poderá solicitar, nos termos legais, a exclusão definitiva dos seus dados, que só poderá ser realizada após decorrido o prazo legal de 6 (seis) meses de guarda obrigatória de registro. A Go Gamers poderá ser compelida a manter por prazo superior a 6 (seis) meses tais registros, por força de ordem de autoridade policial, administrativa ou do Ministério Público. O membro poderá solicitar acesso aos seus próprios dados guardados pela Go Gamers. A Go Gamers poderá ser compelida a disponibilizar judicialmente os dados que mantém sob sua guarda. Aplicam-se os termos da Lei 12.965/2014 às ocasiões não previstas nesta Política de Privacidade.</p>
      <br></br>


      <h2>DAS DISPOSIÇÕES GERAIS, LEGISLAÇÃO APLICÁVEL E FORO DE ELEIÇÃO</h2>
      <br></br>
      <p>As disposições constantes desta Política de Privacidade poderão ser atualizadas ou modificadas a qualquer momento, sendo de inteira responsabilidade dos membros tomar ciência da mesma no momento da navegação em nosso site, sendo responsável por verificá-la sempre que efetuarem acesso ao portal da GO GAMERS. As atualizações e modificações nos termos da Política de Privacidade entram em vigência no ato de sua publicação no nosso portal, não subsistindo eventual alegação de desconhecimento dos termos pelo membro.</p>
      <br></br>
      <p>A presente Política de Privacidade será interpretada de acordo com a legislação brasileira, seguindo o idioma português, sendo eleito o Foro da Comarca da cidade de Fortaleza no Estado do Ceará para dirimir qualquer litígio, questão ou dúvida superveniente, com expressa renúncia de qualquer outro, por mais privilegiado que seja.</p>
      <br></br>
    </PoliticaContainer >
  )
};