import styled from 'styled-components';

import { device } from '../../../../utils';

export const ImageArena = styled.img`
  max-width: 20rem;
  margin-bottom: -100px;

  @media ${device.desktopSmall} {
    max-width: 60rem;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  justify-content: center;
  min-height: 100vh;
`;

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8rem 2rem 4rem 4rem;

  @media ${device.desktopSmall} {
    padding: 8rem 2rem 8rem 8rem;
  }
`;

export const Title = styled.h1`
  font-size: 30pt;
  text-align: left;
  letter-spacing: 0rem;
  color: #e94560;
  opacity: 1;

  @media ${device.mobileLarge} {
    font-size: 35pt;
    text-align: center;
  }
`;

export const Description = styled.p`
  font-size: 12pt;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  color: var(--text-color);
  padding: 2rem 0;
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 0 2rem;
  margin-top: 3rem;
`;

export const PrimaryAction = styled.a`
  font-size: 12pt;
  letter-spacing: 0px;
  background: var(--layout-color);
  color: var(--text-color);
  opacity: 1;
  text-align: center;
  border-radius: 5rem;
  border: 1px solid var(--layout-color);
  padding: 0.5rem 1rem;
  cursor: pointer;

  box-shadow: 0rem 0rem 1rem var(--layout-color);
  border-radius: 4px;

  @media ${device.desktopSmall} {
    padding: 1.5rem 3rem;
  }
`;

export const SecondaryAction = styled.a`
  font-size: 12pt;
  letter-spacing: 0px;
  background: transparent;
  color: var(--layout-color);
  opacity: 1;
  text-align: center;
  border-radius: 5rem;
  border: 1px solid var(--layout-color);
  padding: 0.5rem 1rem;
  cursor: pointer;

  border-radius: 4px;

  @media ${device.desktopSmall} {
    padding: 1.5rem 3rem;
  }
`;
