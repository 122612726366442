
import {
  CardContainer,
  ImageContainer,
  CardChampionshipImage,
  CardGameImage,
  DetailsContainer,
  InfoContainer,
  CardTitle,
  CardFooterContainer,
  CardSmallInfoContainer,
  CardSmallInfoText,
} from './styles';

const Card = ({
  gameId,
  championshipName,
  championshipImg,
  gameImg,
  date,
  inProgress,
  isEnd,
  maxUsers,
  squad,
  result,
}) => {

  let dateFormated = new Date(date);
  let actualDate = new Date();
  dateFormated.setHours(14);

  const differenceInMs = dateFormated - actualDate;

  let daysLeft = differenceInMs / (1000 * 60 * 60 * 24);

  return (
    
    <CardContainer>
      <ImageContainer>
        <CardGameImage src={gameImg} alt="Campeonato" />

        <CardChampionshipImage src={championshipImg} alt="Campeonato" />

        <DetailsContainer>
          {!inProgress && !isEnd && daysLeft <= 0 && <span>🔒 Inscrições <strong>encerradas</strong>, aperte para ver o chaveamento</span>}
          {!inProgress && !isEnd && daysLeft > 0 && <span>🕒 Inscrições encerram em  <strong>{daysLeft.toFixed()} dias</strong>, aperte para se inscrever</span>}
          {inProgress && !isEnd && <span>🎮 Campeonato em <strong>progresso</strong>, aperte para acompanhar o chaveamento!</span>}
          {isEnd && daysLeft <= 0 && <span>⌛ Campeonato <strong>encerrado</strong>, aperte para ver o chaveamento</span>}
        </DetailsContainer>
      </ImageContainer>

      <InfoContainer>
        <CardTitle>{championshipName}</CardTitle>

        <CardFooterContainer>
          <CardSmallInfoContainer>
            <CardSmallInfoText>📅 {`${dateFormated.getDate()}/${dateFormated.getMonth() + 1}/${dateFormated.getFullYear()}`}</CardSmallInfoText>
          </CardSmallInfoContainer>

          <CardSmallInfoContainer>
            <CardSmallInfoText>
              👥 {Math.floor(0 / 5)}/{maxUsers}
            </CardSmallInfoText>
          </CardSmallInfoContainer>

          <CardSmallInfoContainer>
            <CardSmallInfoText>⚔️ {squad}</CardSmallInfoText>
          </CardSmallInfoContainer>
        </CardFooterContainer>
      </InfoContainer>
    </CardContainer>
  );
};

export default Card;
