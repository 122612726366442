import { RegulamentoContainer } from './styled';

export default function RegulamentoCS() {
  return (
    <RegulamentoContainer>
      <p>
        <strong>
          No ano vigente o torneio será um campeonato presencial, sendo uma iniciativa da parceria
          entre Ibyte e ProGamers.
        </strong>
      </p>
      <br />
      <p>
        A plataforma escolhida para a realização do campeonato será a da Go Gamers. Onde todo o
        processo de inscrição, rankeamento e divisão de grupos será feito pela plataforma da
        ProGamers.{' '}
      </p>
      <br />
      <h2>DAS INSCRIÇÕES</h2>
      <br />
      <p> As inscrições serão disponibilizadas online, na forma adiante disposta:</p>
      <br />
      <br />
      <h2>1. Elegibilidade de Jogadores</h2>
      <br />
      <p>
        Para participar do campeonato da Arena Gamer Ibyte & Lenovo, cada jogador deve preencher os
        requisitos previstos neste regulamento, comportando-se de forma esportiva, assim como
        respeitando os adversários e a organização do evento.
      </p>
      <br />
      <h2>1.1 Residência</h2>
      <br />
      <h2>1.2 Inscrições duplicadas</h2>
      <br />
      <p>
        Cada jogador só poderá se inscrever em uma única equipe. Caso um jogador venha a se
        inscrever e/ou a jogar em mais de uma equipe, ambas as equipes e seus jogadores serão
        desclassificados.
      </p>
      <br />
      <h2>2. Dinâmica da Temporada</h2>
      <br />
      <p>
        Evento Presencial: As equipes semifinalistas dos playoffs terão a oportunidade de competir
        em um evento presencial emocionante. Neste evento, as equipes se enfrentarão para determinar
        o grande campeão.
      </p>
      <br />
      <h2>2.2 Agenda de jogos</h2>
      <br />
      <h2>2.2.1 Premiação e inscrição</h2>
      <br />
      <p>
        O Torneio premiará as 4 (quatro) equipe finalistas e MVP (melhores jogadores) , e a
        distribuição será na forma adiante exposta:
      </p>
      <br />
      <p>- 1º Lugar: R$2.000,00</p>
      <p>- 2º Lugar: R$1.500,00</p>
      <p>- 3º Lugar: R$1.000,00</p>
      <br />
      <h2>3. Requisitos das Equipes</h2>
      <br />
      <br />
      <p>
        Além dos 5 (cinco) jogadores da formação principal, serão admitidas as alterações na equipe
        titular, que podem ocorrer conforme a seção 3.1 do regulamento.
      </p>
      <br />
      <h2>3.1 Substituição de jogadores</h2>
      <p>
        Caso por um evento fortuito aconteça e um jogador não tenha mais condição de seguir no
        campeonato durante o andamento do mesmo, esse pode ser removido da equipe para ser colocado
        outro. No entanto, o jogador substituído não poderá mais jogar mais quaisquer fases do
        evento; (mais detalhes podem ser conferidos no artigo 3.1.3).
      </p>
      <br />
      <p>
        Se ficar comprovado que o mesmo player jogou por duas ou mais equipes durante a mesma o
        evento, em contas diferentes ou não, o mesmo estará automaticamente excluído do evento e
        poderá ser punido para eventos posteriores.
      </p>
      <br />
      <h2>3.1.1 Substituições Casuais</h2>
      <br />
      <p>
        Caso seja necessária substituição de jogadores antes do início da partida, essa deverá ser
        realizada por jogadores previamente inscritos entre os 7 players do time.
      </p>
      <br />
      <h2>3.1.2 Substituições mandatórias</h2>
      <br />
      <p>
        Podem ser aceitas substituições pela organização do evento, em caso de óbito, incapacidade
        de jogar, suspensão ou banimento em caso de não cumprimento das regras.
      </p>
      <br />
      <h2>3.1.3 A equipe</h2>
      <br />
      <h2>3.1.4 Formação da equipe</h2>
      <br />
      <p>
        As equipes devem ser formadas por no mínimo 5 players e no máximo 7 players, podendo ser
        adicionado um Coach. A equipe deve estar completa no momento da inscrição com o número
        mínimo de players. Não existe a necessidade de ter um Coach para disputar a competição.
      </p>
      <br />
      <h2>3.1.5 Qualificação de cada Player</h2>
      <br />
      <h2>4. Cronograma</h2>
      <br />
      <p>
        As inscrições para participação das Arena Gamer Ibyte & Lenovo podem ser realizadas até o
        termino de inscrições de cada Seletiva, podendo ser prorrogadas a critério da organização.
        Os jogos se desenvolverão nas datas adiante estipuladas:
      </p>
      <br />
      <h2>5. Configurações gerais Counter Strike 2</h2>
      <br />
      <h2>5.1 Mapas</h2>
      <br />
      <p>Mapas permitidos:</p>
      <br />
      <p>de_inferno</p>
      <p>de_ancient; </p>
      <p>de_mirage;</p>
      <p>de_vertigo; </p>
      <p>de_overpass;</p>
      <p>de_anubis; </p>
      <p>de_nuke.</p>
      <br />
      <h2>5.2 Tempo limite e atrasos</h2>
      <br />
      <p>
        Os jogadores deverão encontrar-se prontos para a partida no seu horário estipulado. O tempo
        limite de atraso será de 15 minutos corridos a partir do horário da partida para haver, no
        mínimo, 4 jogadores da equipe participante. Havendo, no mínimo, 4 jogadores da equipe serão
        acrescidos mais 15 minutos para ser sanado qualquer problema técnico, seja ele problema de
        anti-cheat, ou problema de conta, internet ou de qualquer tipo que seja, não havendo
        tolerância após os 30 minutos, salvo a substituição informada no item 5.3.2.
      </p>
      <br />
      <p>
        A partir disso, poderá ser solicitado o W.O. A solicitação do W.O. deverá partir da equipe
        que julgar possuir o direito para tal, considerando a aderência a todos os requisitos acima
        listados. A organização irá avaliar se o pedido está conforme as regras e notificará os
        presentes de ambas as equipes.
      </p>
      <br />
      <h2>5.2.1 Pauses e tempo de pause.</h2>
      <br />
      <p>
        Cada equipe tem direito a 4 pauses de 30 segundos por mapa. A pausa deve ser solicitada pelo
        no chat pelo comando !pause por 1 membro da equipe, ou em caso de impossibilidade, ou
        problemas técnicos solicitar através do comando !tec. A decisão sobre a permissão da pausa é
        do administrador da partida. O tempo da pausa é contado pelo sistema e a partida só será
        reiniciada após o termino do tempo ou solicitado para o administrador.
      </p>
      <br />
      <h2>5.3 Jogador desconectado</h2>
      <br />
      <p>
        No caso de desconexão de quaisquer dos jogadores do time, deverá haver a utilização
        ordinária das pausas, as quais serão na forma pré-estabelecida no servidor, ou, não havendo
        limite de tempo, no prazo de 5 minutos.
      </p>
      <br />
      <p>
        É possível a substituição por jogador já inscrito no campeonato, caso assim seja permitido
        no servidor hospedeiro do campeonato.
      </p>
      <br />
      <h2>5.3.2 Substituição</h2>
      <br />
      <p>
        A organização tolera a utilização da conta de jogador desconectado com problema no hardware
        ou de internet, ou até mesmo em caso de atualização do jogo, desde que o jogador que será o
        substituto esteja cadastrado no time desde o início da competição e que haja prévia
        informação desse fato.
      </p>
      <br />
      <h2>5.4 Deixar o servidor</h2>
      <br />
      <p>Deixar o servidor é proibido, todas as partidas deverão ser jogadas até o final.</p>
      <br />
      <p>Não completar um jogo poderá acarretar em punição.</p>
      <br />
      <h2>5.5 Alteração no jogo</h2>
      <br />
      <p>
        Qualquer tipo de alteração feita no jogo diretamente ou através de softwares serão passíveis
        de punição.
      </p>
      <br />
      <h2>5.6 Software de Comunicação</h2>
      <br />
      <p>
        Para o chat de voz poderá ser utilizado o próprio jogo, Teamspeak ou Discord ou outro a
        critério das equipes.
      </p>
      <br />

      <h2>5.8 Classificação</h2>
      <br />
      <h2>5.9 Termo de Responsabilidade:</h2>
      <br />
      <ul>
        <li>
          Todas as equipes e seus respectivos jogadores participantes da etapa presencial devem
          assinar um termo de responsabilidade.
        </li>
        <li>
          O termo de responsabilidade atesta que os jogadores estão cientes das regras e concordam
          em cumprir todas as normas estabelecidas pela organização do evento.
        </li>
        <li>
          A não assinatura do termo de responsabilidade implica na impossibilidade de receber
          premiação e pode resultar na perda da vaga da equipe.
        </li>
        <li>
          O termo de responsabilidade também confirma a presença dos jogadores na etapa presencial
          do evento.
        </li>
      </ul>
      <br />
      <h2>6. Reinício de Partida</h2>
      <br />
      <p>
        Somente a organização pode autorizar o reinício de uma partida sem que haja contagem de
        pontos. Nos item 6.1 são listados possíveis reinícios de partida, que caso aconteça cabe aos
        jogadores identificar e informar à organização.
      </p>
      <br />
      <h2>6.1 Casos possíveis</h2>
      <br />
      <p>Caso todos os jogadores ativos forem desconectados simultaneamente.</p>
      <br />
      <p>
        Em caso de necessidade de reinício das partidas, as mesmas serão iniciadas com placar de
        0x0, independentemente da quantidade de rounds que já tenha sido realizado e do placar em
        que se encontrava.
      </p>
      <br />
      <p>
        A organização não se responsabiliza por problemas de internet e desconexão de jogadores
        durante as partidas online.
      </p>
      <br />
      <h2>7. Conduta dos Jogadores</h2>
      <br />
      <p>
        Os jogadores devem manter espírito de equipe e zelar pelo andamento do Campeonato. Qualquer
        atitude que seja negativa, injusta, prejudicial para o bom desenvolver do campeonato ou que
        desrespeitem as regras a seguir poderá ser punida com advertência e até mesmo a
        desclassificação do jogador e da equipe, caso sejam recebidas três advertências.
      </p>
      <br />
      <h2>7.1 Hacking</h2>
      <br />
      <p>
        Qualquer alteração no jogo ou até mesmo na pasta de instalação no jogo será considerada como
        hacking, independente de quem tenha feito à alteração, caso o jogador se beneficie dela, ou
        seja, identificada poderá ser punido.
      </p>
      <br />
      <h2>7.1.1 Denúncias</h2>
      <br />
      <p>
        Qualquer denúncia referente a uma partida deverá ser realizada através do Whatsapp, logo
        após o término da mesma para que possamos analisar. Caso o próximo jogo da equipe que
        recebeu a denúncia já tenha iniciado, iremos desconsiderar o pedido.
      </p>

      <br />
      <h2>8. Sujeição a Penalidades</h2>
      <br />
      <p>
        Caso uma equipe assine o termo e não compareça a uma partida presencial, a equipe será
        automaticamente desqualificada da competição e perderá o direito a qualquer premiação.
      </p>
      <br />
      <h2>8.1. Geral</h2>
      <br />
      <p>Todas as Sanções serão determinadas pela administração do torneio com total descrição.</p>
      <br />
      <p>
        Os administradores do Torneio podem aplicar sanções mais graves em casos em que a infração
        seja repetida pelo time.
      </p>
      <br />
      <h2>9. Penalidades</h2>
      <br />
      <p>
        Após a descoberta de violação por um membro de uma equipe de qualquer uma das regras
        listadas acima, a organização do Campeonato pode, sem limitação de sua autoridade, emitir as
        seguintes penalidades:
      </p>
      <br />
      <p>Advertência Verbal;</p>
      <p>Perda de Jogos; </p>
      <p>Desclassificações.</p>
      <br />
      <p>
        No acúmulo de três advertências sobre a mesma conduta, juntamente com a terceira vem a perda
        de 3 pontos. Caso acumule uma quarta a equipe será removida do torneio.
      </p>
      <br />
      <p>
        Infrações repetidas ou graves (abusos, hack, etc) estão sujeitas a escalação de penalidades,
        até, e incluindo, a desclassificação de eventos futuros. É importante notar que penalidades
        não precisam seguir uma ordem. A critério da comissão julgadora do Campeonato, um jogador
        pode ser desclassificado por uma primeira ofensa se a ação do jogador for suficiente para
        uma desclassificação.
      </p>
      <br />
      <h2>10. Regulamento</h2>
      <br />
      <p>
        As equipes que se inscreverem concordam desde já, com todas as normas e regulamentos
        dispostos nesse documento.
      </p>
      <br />
      <p>
        A inscrição na qualificatório para Ibyte funciona como concordância com todas as regras, que
        deverão ser cumpridas pelas equipes e/ou jogadores, que renunciam, desde já, a toda e
        qualquer reclamação, inclusive, na esfera judicial. Em caso de omissão do presente
        regulamento caberá a uma Comissão Julgadora emitir decisões.
      </p>
      <br />
      <h2>10.1. Interpretação das Regras</h2>
      <br />
      <p>
        Todas as interpretações das regras, penalidades e demais decisões são uma prerrogativas
        oficiais da ProGamers e Ibyte são finais.
      </p>
      <br />
      <h2>10.2. Critério de Desempate</h2>
      <br />
      <p>1 - Overtime até sair 1 vencedor</p>
      <br />
      <p>Em caso de empate em fase de grupos (se houver)</p>
      <p>1- Confronto Direto</p>
      <p>2- Saldo de Rounds</p>
      <br />
      <p>Se persistir empatado uma md1 pra desempatar</p>
      <br />
      <h2>11. Uso de imagens</h2>
      <br />
      <p>
        A Go Gamers poderá divulgar imagens via redes sociais, e-mail/Whatsapp marketing, rádio,
        televisão e/ou qualquer meio de comunicação, em qualquer tipo de mídia e/ou plataforma para
        toda e qualquer finalidade pretendida pela organização do evento. A efetivação da inscrição
        configura-se como anuência e cessão dos direitos do uso de imagem das equipes e de seus
        jogadores.
      </p>
      <br />
      <h2>11.1. Decisões Finais</h2>
      <br />
      <p>
        Em caso de equipes convocadas nos termos deste edital não efetue a confirmação de presença,
        caberá a equipe organizadora convidar nova(s) equipe(s).
      </p>
      <br />
      <h2>12. Transmissão</h2>
      <br />
      <p>
        A Arena Gamer Ibyte & Lenovo poderão ser transmitidos pela administração do torneio e seus
        parceiros, e a seu exclusivo critério, em qualquer canal de tv aberta e/ou fechada, internet
        e/ou qualquer plataforma. A efetivação da inscrição configura-se como anuência das equipes e
        de seus jogadores, que se comprometem, desde já, a assinar termo declarando a concordância à
        cessão de direitos de arena, imagem e voz captados durante o campeonato e/ou em todo e
        qualquer material produzido durante o presente campeonato e/ou em decorrência dele, ainda
        que com finalidade de divulgação, fomentação e/ou publicidade, renunciando, ainda, a
        qualquer ação judicial contra a administração do evento e seus parceiros.
      </p>
      <br />
      <p>
        As transmissões oficiais ocorrem conforme o horário estabelecido dos agendamento das
        partidas pela organização, podendo ocorrer partidas em simultâneo, mas apenas transmitindo
        uma por vez.
      </p>
      <br />
      <p>A terça-feira poderá ser excluída em razão de problemas com atualização da steam.</p>
      <br />
      <p>
        A organização do campeonato se reserva, na hipótese de conflito de horários entre partidas,
        a permitir que streamer parceiro realize a transmissão dos jogos.
      </p>
      <br />
      <h2>
        A organização do campeonato não possui qualquer responsabilidade sobre a transmissão de
        participante do torneio que esteja realizando a stream de seu jogo, não sendo regulada pela
        competição a conduta denominada “Ghost ou tela”, é de inteira responsabilidade do referido
        jogador a sua realização. (disposição de acordo com o art. 15 da competição).
      </h2>
      <br />
      <h2>12.1 Forma de transmissão</h2>
      <br />
      <p>
        A transmissão das partidas será realizada por streamer indicado pela administração do
        evento, e tal situação como objetivo promover a publicidade dos jogos e a interação do
        público com os participantes e streamers. Ficam cientes os participantes que a opinião
        externada em cada plataforma de transmissão é de única responsabilidade daquele que a emitiu
        e não reflete a orientação dessa organização.
      </p>
      <br />
      <h2>13. Direito de Publicação</h2>
      <br />
      <p>
        A administração do evento terá todos os direitos de publicar e declarar que um membro da
        equipe foi penalizado. Qualquer membro da equipe ou time poderá ser citado nessas
        declarações, renunciando assim a qualquer direito de ação judicial.
      </p>
      <br />
      <h2>14. Decisões Finais</h2>
      <br />
      <p>
        Todas as decisões a respeito da interpretação das regras, elegibilidade de jogadores,
        cronograma do Campeonato e penalidades por mau comportamento dependem apenas das decisões da
        organização e comissão julgadora, que são finais. As decisões, em respeito a essas regras,
        não podem ser objeto de recurso e não darão origem a qualquer reclamação por danos
        monetários ou qualquer outra medida legal.
      </p>
      <br />
      <p>
        Omissão acerca de qualquer ponto do presente regulamento será objeto de consulta a todos os
        capitães das equipes envolvidas e a decisão será tomada por maioria simples.
      </p>
      <br />
      <h2>15. Alteração das Regras</h2>
      <br />
      <p>
        Estas regras podem ser alteradas, modificadas ou complementadas pela organização do
        Campeonato a qualquer momento. Em caso de alterações, as equipes inscritas serão
        imediatamente avisadas.
      </p>
      <br />
    </RegulamentoContainer>
  );
}
