import styled from 'styled-components';

import { device } from '../../../utils';

export const ContatoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 2rem;

  
`;

export const ContatoTitle = styled.p`
  font-size: 18pt;
  font-weight: bold;
  text-align: center;
  color: var(--layout-color);
  margin-bottom: 3rem;
`;

export const ContatoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  

  @media ${device.desktopSmall} {
    align-items: flex-start;
  }
`;

export const ContatoImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ContatoImg = styled.img`
  width: 40px;

  cursor : pointer;

  transition-duration:1s;

  &:hover {
    transition-duration: 0.8s;
    transform: scale(1.1);
  }

  @media ${device.desktopSmall} {
    width: 50px;
  }
`;

export const ContatoLink = styled.a``;