import { IoClose } from 'react-icons/io5';
import { FcCheckmark } from "react-icons/fc";
import {
  ButtonCloseModal,
  TextModalConfirm,
  CloseButtonContainer,
  CheckIconModal,
  ContatoImgWrapper,
  ContatoImg,
  ContatoLink,
} from './styles.js'

import Instagram from '../../assets/ativos/InstagramFooter.svg';
import Discord from '../../assets/ativos/DiscordFooter.svg';
import Twitch from '../../assets/ativos/TwitchFooter.svg';
import { INSTAGRAM_URL, TWITCH_URL, DISCORD_URL } from '../../utils/const.js';


export default function confirmContainer({ fecharModal }) {
  return (
    <>
      <CloseButtonContainer onClick={fecharModal}>
        <IoClose size={30} className="closeButtonIcon" />
      </CloseButtonContainer>

      <CheckIconModal>
        <FcCheckmark size={100} />
      </CheckIconModal>

      <TextModalConfirm>
        <h2>Obrigado pela sua inscrição, verifique em seu email a confirmaçao!</h2>
        <p>Siga-nos nas redes sociais para ficar por dentro das próximas etapas do campeonato!</p>
      </TextModalConfirm>

      <ContatoImgWrapper>
        <ContatoLink href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
          <ContatoImg src={Instagram} />
        </ContatoLink>
        <ContatoLink href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
          <ContatoImg src={Discord} />
        </ContatoLink>
        <ContatoLink href={TWITCH_URL} target="_blank" rel="noopener noreferrer">
          <ContatoImg src={Twitch} />
        </ContatoLink>
      </ContatoImgWrapper>

      <ButtonCloseModal>
        <button onClick={fecharModal}>Fechar</button>
      </ButtonCloseModal>
    </>
  )
}