import styled from 'styled-components';

import { device } from '../../utils';

export const RodapeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktopSmall} {
    align-items: center;

    padding: 5rem 0;
  }
`;

export const RodapeMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktopSmall} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const RodapeLogoContatoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.desktopSmall} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const RodapeLinkMenusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.desktopSmall} {
    flex-direction: row;
    align-items: flex-start;

    > div {
      width: 200px;
    }
  }
`;

export const RodapeCopyrightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 2rem 0;
`;

export const RodapeCopyright = styled.p`
  font-size: 10pt;
  text-align: center;
  color: var(--text-color);
  text-decoration: none;
`;
