import styled from 'styled-components';

import { device } from '../../../utils';

export const LeftContainer = styled.div`
  display: flex;
  gap: 3rem;
`;

export const HeaderLogo = styled.img`
  width: 5rem;
  margin: 0 0 0 1rem;

  @media ${device.desktopSmall} {
    width: 8rem;
    margin: 0 0 0 2rem;
  }
`;
