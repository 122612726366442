import styled from "styled-components";

export const RegulamentoContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  margin: 0 4rem 0 4rem;
  color: #FFFF;
  ul{
    margin-left: 20px;
  }
  a{
    color: #FFFF;
  }
`