import React from 'react';
import { InfoCampeonatoContainer } from '../../styled';
import dateFormat, { masks } from 'dateformat';
import { i18n } from 'dateformat';

i18n.dayNames = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

i18n.monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function InfoCampeonato(
  date,
  game,
  currentUsers,
  maxUsers,
  squad,
  inforCampeonatoTopic,
  inforFormato,
  awardDescription,
  requirements,
  timeline,
  rulesTopics,
  observationsTopics,
  award,
  gameId,
) {
  return (
    <>
      <InfoCampeonatoContainer>
        <div className="inforCampeonato">
          <div className="inforText">
            <div className="left">
              <div className="infoContent">
                {inforCampeonatoTopic.map((topic, index) => {
                  return <p key={index}>{topic}</p>;
                })}
              </div>
              <div className="infoContent">
                <div className="timelineText">
                  <h3>Premiação:</h3>
                </div>
                <p>{awardDescription}</p>
              </div>
              <div className="infoContent">
                <div className="timelineText">
                  <h3>Requerimentos:</h3>
                </div>
                {requirements?.map((topic, index) => {
                  return <li key={index}>{topic}</li>;
                })}
              </div>
              {/* <div className='timelineContent'>
                                <div className='timelineText'><h3>Cronograma</h3></div>
                                {
                                    timeline.map((topic, index) => {
                                        return (
                                            <li key={index}>{topic}</li>
                                        )
                                    })
                                }
                            </div> */}

              <div className="timelineContent">
                <div className="timelineText">
                  <h3>Regras:</h3>
                </div>
                {rulesTopics.map((topic, index) => {
                  return <p key={index}>{topic}</p>;
                })}
              </div>

              <div className="timelineContent">
                <div className="timelineText">
                  <h3>Observações:</h3>
                </div>
                {observationsTopics.map((topic, index) => {
                  return <p key={index}>{topic}</p>;
                })}
              </div>

              {inforFormato !== '' ? (
                <div className="timelineContent">
                  <div className="timelineText">
                    <h3>Configurações de Jogo:</h3>
                  </div>
                  {inforFormato.map((topic, index) => {
                    return (
                      <ul>
                        <li>
                          <p key={index}>{topic}</p>
                        </li>
                      </ul>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
          <div className="right">
            <div className="infoFormato">
              <h3>Formato</h3>
            </div>
            <div className="boxHolder">
              <div className="box Data">
                📅 {dateFormat(date, (masks.Test = 'd "de" mmmm "de" yyyy'))}
              </div>
              <div className="box Jogo">{game ? game : 'game goes here'}</div>
              <div className="box Inscritos">
                👥 {currentUsers}/{maxUsers}
              </div>
              <div className="box Squad">⚔️ {squad}</div>
            </div>
            <div className="infoPremiacao">
              {award.map((item) => {
                return (
                  <div key={item.id} className="containerPremiacao">
                    <div className="headerPremiacao">
                      {item.icon}
                      <small>{item.name}</small>
                    </div>
                    <h3>{item.amount}</h3>
                  </div>
                );
              })}
            </div>
            <small className='waringPremiacao'><span>*</span>O valor premiação é referente a itens GT Gamer</small>
          </div>
        </div>
      </InfoCampeonatoContainer>
    </>
  );
}
