import React from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import AvisoCookies from './components/avisoCookies/avisoCookies.jsx'
import AreaCampeonatos from './components/areaCampeonatos/AreaCampeonatos.jsx';
import BoasVindas from './components/boasVindas/BoasVindas.jsx';
import Rodape from '../../components/rodape/Rodape.jsx';
import SideBar from './components/sideBar/SideBar.jsx';
import FAQ from "./components/FAQ/Faq.jsx";

import background from '../../assets/ativos/BG.png';

import { Container } from './styles';

const HomePage = () => {
  const [searchParams] = useSearchParams();
  const [cookie, setCookie] = useCookies(['utm_source']);

  if (cookie.utm_source) {
 //   console.log("Your utm_source it's already salved in cookies storage");
  } else {
    let expireDate = new Date();
    expireDate = new Date(
        expireDate.setDate(expireDate.getDate() + 365)
    );
    setCookie('utm_source', searchParams.get("utm_source"), { expires: expireDate });
   // console.log('Your utm_source  was saved in cookies storage')
  }

  return (
    <Container backgroundImage={background}>
      <AvisoCookies />
      <SideBar />
      <BoasVindas />
      <AreaCampeonatos />
      <FAQ />
      <Rodape />
    </Container >
  );
};

export default HomePage;
