import { useState, useEffect } from 'react';
import axios from 'axios';

import {
  CardContainer,
  ImageContainer,
  CardChampionshipImage,
  CardGameImage,
  DetailsContainer,
  InfoContainer,
  CardTitle,
  CardFooterContainer,
  CardSmallInfoContainer,
  CardSmallInfoText,
} from './styles';

const Card = ({
  gameId,
  championshipName,
  championshipImg,
  gameImg,
  date,
  inProgress,
  isEnd,
  currentUsers,
  maxUsers,
  squad,
  result,
}) => {

  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  };

  const [currentSubs, setCurrentUsers] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (squad === "Solo") {
      axios.get(`https://ostem.gogamers.pro/enrollments/count/${gameId}`, headers)
      .then((response) => {
        setCurrentUsers(response.data.data.count)
      })
      .catch((error) => {
        console.error(error)
      })
    } else {
      axios.get(`https://ostem.gogamers.pro/enrollments/countTeam/${gameId}`, headers)
      .then((response) => {
        setCurrentUsers(response.data.data.count)
      })
      .catch((error) => {
        console.error(error)
      })
    }
   });

  let dateFormated = new Date(date);
  let actualDate = new Date();
  dateFormated.setHours(17);

  const differenceInMs = dateFormated - actualDate;

  let daysLeft = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

  return (
    
    <CardContainer>
      <ImageContainer>
        <CardGameImage src={gameImg} alt="Campeonato" />

        <CardChampionshipImage src={championshipImg} alt="Campeonato" />

        <DetailsContainer>
          {!inProgress && !isEnd && daysLeft < 0 && <span>🔒 Inscrições <strong>encerradas</strong>, aperte para ver o chaveamento</span>}
          {!inProgress && !isEnd && daysLeft > 0 && <span>🕒 Inscrições encerram em  <strong>{daysLeft.toFixed()} dias</strong>, aperte para se inscrever</span>}
          {!inProgress && !isEnd && daysLeft === 0 && <span>🕒 Inscrições encerram <strong>hoje</strong>, aperte para se inscrever</span>}
          {inProgress && !isEnd && <span>🎮 Campeonato em <strong>progresso</strong>, aperte para acompanhar o chaveamento!</span>}
          {isEnd && daysLeft <= 0 && <span>⌛ Campeonato <strong>encerrado</strong>, aperte para ver o chaveamento</span>}
        </DetailsContainer>
      </ImageContainer>

      <InfoContainer>
        <CardTitle>{championshipName}</CardTitle>
        <br/>

        <CardFooterContainer>
          <CardSmallInfoContainer>
            <CardSmallInfoText>📅 {`${dateFormated.getDate()}/${dateFormated.getMonth() + 1}/${dateFormated.getFullYear()}`}</CardSmallInfoText>
          </CardSmallInfoContainer>

          <CardSmallInfoContainer>
            <CardSmallInfoText>
              👥 {currentSubs}/{maxUsers}
            </CardSmallInfoText>
          </CardSmallInfoContainer>

          <CardSmallInfoContainer>
            <CardSmallInfoText>⚔️ {squad}</CardSmallInfoText>
          </CardSmallInfoContainer>
        </CardFooterContainer>
      </InfoContainer>
    </CardContainer>
  );
};

export default Card;
