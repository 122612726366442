import styled from "styled-components";
import background from '../../assets/ativos/BG.png';
import { device } from '../../utils/'

const ContainerCampeonato = styled.div`
    background-image: url(${background});
    background-repeat:  no-repeat;
    background-size: cover;
    background-position: center;

    .inforText{
        display: flex;
        flex-direction: column;
        max-width:1000px;
        width:100%;
    }
    

    .callCampeonato{
        background-repeat:  no-repeat ;
        background: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .tituloCampeonato{
        text-align: center;
        font: normal normal bold 20px/31px Poppins;
        letter-spacing: 0px;
        padding-top:60px;
        color: #FFFFFF;
        opacity: 1;
    }

    .details, .chaveamento, .regulamento, .participantes{
        cursor: pointer;
    }

    .chaveamento-waring{
        padding-inline:15px ;
        color:#ffff;
        text-align: center;
        margin:auto;
        font-size:20px;
    }

    button.inscrever{
        align-self:center;
        justify-self:center;
        background: #E94560 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 40px #E94560;
        opacity: 1;
        text-align: center;
        font: normal normal normal 15px/20px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        border:0px;
        border-radius:5px;
        padding: 15px 40px 15px 40px;
        margin-top:35px;
    }

    button.inscreverDisable {
        align-self:center;
        cursor: not-allowed;
        filter: brightness(80%);
        justify-self:center;
        background: #E94560 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 40px #E94560;
        opacity: 1;
        text-align: center;
        font: normal normal normal 15px/20px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        border:0px;
        border-radius:5px;
        padding: 15px 40px 15px 40px;
        margin-top:35px;  
    }


    .tempoInscrever{
        font: normal normal 300 13px/36px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-top:25px;
        margin-bottom:70px;
    }
    
    .bar{
        display:flex;
        text-align: center;
        gap:20px; 
        overflow-x: auto;
        margin: 2rem 2rem 0 2rem;
        font: normal normal normal 15px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        border-bottom: 0.5px solid #E94560;
    }
 
    .active{
       border-bottom:  2px solid #E94560;
    }

    .CardParticipantesContent{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;
        padding-inline: 60px;
    }

    .cardParticipantes{
        display: flex;
        position: relative;
        padding: 15px;
        font-size: 80%;
        width: 200px;
        height: 140px;
        justify-content: center;
        flex-direction: column;
        background: #0C1A27 0% 0% no-repeat padding-box;
        border-radius: 7px;
        opacity: 1;
        color: #FFFFFF;
        text-align: center;
    }

    .cardParticipantes h5{
        font-weight: normal;
    }

    .textParticipantes{
        margin-bottom:6px;
        font-weight: bold;
    }

    @media ${device.desktopSmall} {
        .callCampeonato{
         height:500px;
        }
        .cardParticipantes{
            font-size: 100%;
            width: 300px;
            height: 150px;
        }

        .CardParticipantesContent{
            padding-inline: 100px; 
        }

        .tituloCampeonato{
         font: normal normal bold 37px/41px Poppins;
        }
        
        button.inscrever{
            font: normal normal normal 20px/30px Poppins;
            padding: 15px 55px 15px 55px;
            margin-top:40px;
        }
        .tempoInscrever{
            font: normal normal 300 16px/46px Poppins;
        }
        .bar{
            margin: 2rem 4rem 0 4rem;
            font: normal normal normal 24px Poppins;
        }
        .chaveamento-waring{
            font-size:25px;
        }
        .bar-scroll{
            gap: 40px;
        }
    }
`

const InfoCampeonatoContainer = styled.div`
    padding-top:10px;
    margin: 0 4rem 0 4rem;
 
    
    .inforCampeonato{
        display: flex;
        flex-direction: row;
        gap:2rem;
        font-size: 80%;

        @media ${device.desktopSmall} {
            font-size: 100%;
        }
    }

    .left{
        flex:6;
        text-align: left;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }

    .infoContent, .timelineContent{
        font: normal  15px/30px Poppins;
        margin-block: 1rem;
    }

    .right{
        flex:4;
        margin-bottom:20px;
        max-width:700px;
        width:100%;

        .infoPremiacao {
            color: #fff;
            display: flex;
            flex-direction: column;
        }

        .containerPremiacao {
            width: 100%;
            background-color: #0C1A27;
            display: flex;
            padding: 10px;
            margin-top: 20px;
            border-radius: 7px;
            justify-content: space-between;
        }

        .headerPremiacao {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .waringPremiacao {
            span {
                font-weight: bold;
            }
            filter: brightness(.6);
            display: flex;
            margin-top: 10px;
            justify-content: center;
            color: #fff;
        }
    }

    .infoFormato h3, .timelineText h3{
        display: flex;
        font: normal normal bold 18px/30px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
    
    .timelineContent{
        margin-top: 20px;
    }


    .boxHolder{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap:15px;
        grid-row-gap:12px;
    }

    .box{
        background: #0C1A27 0% 0% no-repeat padding-box;
        border-radius: 7px;
        opacity: 1;
        text-align: center;
        font: normal normal 300 10px/43px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    @media screen and (max-width: 1500px) {
        .inforCampeonato{
            display: flex;
            flex-direction: column-reverse;
        }

    }

    @media ${device.desktopSmall} {
        padding-top:20px;
        .box{
            font-size: 15px;
        }
        .boxHolder{
            grid-column-gap:20px;
            grid-row-gap:17px;
        }
        .infoFormato h3, .timelineText h3{
            font: normal normal bold 20px/30px Poppins;
         
        }
        .infoContent,.timelineContent{
            font: normal normal 18px/32px Poppins;
        }
        .right{
            width:50%;
        }  
    }
`

const RedLine = styled.div`
height: 2px;
background: transparent linear-gradient(90deg, #102131 0%, #E94560 78%, #102131 100%) 0% 0% no-repeat padding-box;
opacity: 1;
`

export { ContainerCampeonato, RedLine, InfoCampeonatoContainer }