import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    z-index: 999;
    bottom: 0;
    border-radius: 5px;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    opacity: .9;
    max-width: 500px;
    max-height: 200px;
    background-color: rgba(19, 38, 56, .9);
`;

export const Header = styled.h3`
color: #e94560;
`;

export const WaringMessage = styled.small`
color: #fff;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const Button = styled.button`
    width: 100px;
    height: 30px;
    margin-top: 10px;
    color: #fff;
    border: 0;
    border-radius: 5px;
    background: #e94560;
    cursor: pointer;
    box-shadow: 0rem 0rem 1rem #e94560;

    :hover {
        transition: all .3s;
        transform: scale(1.05);
    }
`;
