const breakpoints = {
  mobileSmall: '320px',
  mobileMedium: '375px',
  mobileLarge: '425px',
  tablet: '768px',
  desktopSmall: '1024px',
  desktopMedium: '1440px',
  desktopLarge: '2560px',
};

export const device = {
  mobileSmall: `(min-width: ${breakpoints.mobileSmall})`,
  mobileMedium: `(min-width: ${breakpoints.mobileMedium})`,
  mobileLarge: `(min-width: ${breakpoints.mobileLarge})`,
  tablet: `(min-width: ${breakpoints.tablet})`,
  desktopSmall: `(min-width: ${breakpoints.desktopSmall})`,
  desktopMedium: `(min-width: ${breakpoints.laptopMedium})`,
  desktopLarge: `(min-width: ${breakpoints.desktopLarge})`,
};
