import WhatsApp from '../../../assets/ativos/WhatsAppFooter.svg';
import Instagram from '../../../assets/ativos/InstagramFooter.svg';
import Discord from '../../../assets/ativos/DiscordFooter.svg';
import Twitch from '../../../assets/ativos/TwitchFooter.svg';
import { INSTAGRAM_URL, TWITCH_URL, DISCORD_URL, WHATSAPP_URL } from '../../../utils/const.js';

import {
  ContatoContainer,
  ContatoTitle,
  ContatoContentWrapper,
  ContatoImgWrapper,
  ContatoImg,
  ContatoLink,
} from './styles.js';

const Contato = () => {
  return (
    <ContatoContainer>
      <ContatoContentWrapper>
        <ContatoTitle>Contato</ContatoTitle>

        <ContatoImgWrapper>
          <ContatoLink href={WHATSAPP_URL} target="_blank" rel="noopener noreferrer">
            <ContatoImg src={WhatsApp} />
          </ContatoLink>

          <ContatoLink href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
            <ContatoImg src={Instagram} />
          </ContatoLink>

          <ContatoLink href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
            <ContatoImg src={Discord} />
          </ContatoLink>

          <ContatoLink href={TWITCH_URL} target="_blank" rel="noopener noreferrer">
            <ContatoImg src={Twitch} />
          </ContatoLink>

        </ContatoImgWrapper>
      </ContatoContentWrapper>
    </ContatoContainer>
  );
};

export default Contato; 