import Rodape from '../../components/rodape/Rodape';
import Header from '../../components/header/';
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import ConfirmContainer from 'components/confirmContainer/confirmContainer';
import { ContainerCampeonato, RedLine } from './styled';
import ibyteBg from '../../assets/ativos/bannerSite2.png';
import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';
import InfoCampeonato from './components/InfoCampeonato/InfoCampeonato';
import campDados from '../../data/campDados.json';
import FormContainer from './components/Form/Form';
import '../homePage/components/areaCampeonatos/modal.css';
import RegulamentoValorant from './components/Regulamento/RegulamentoValorant';
import RegulamentoCS from './components/Regulamento/RegulamentoCS';
import RegulamentoLol from './components/Regulamento/RegulamentoLol';

export default function Campeonato({
  championshipName,
  championshipImg,
  timeLeft,
  content,
  squad,
  currentUsers,
  maxUsers,
  date,
  game,
}) {
  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  };

  const [modalIsOpenConfirm, setModalIsOpenConfirm] = useState(false);
  let { pathCampeonato } = useParams();
  let [callDetalhes, setDetalhes] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let [callChaveamento, setChaveamento] = useState(false);
  let [callParticipantes, setCallParticipantes] = useState(false);
  let [callRegulamento, setRegulamento] = useState(false);
  const [currentSubs, setCurrentUsers] = useState(null);
  const [participantes, setParticipantes] = useState([]);

  Modal.setAppElement('#root');

  if (championshipImg == null) {
    championshipImg = ibyteBg;
  }

  function toggleModal() {
    setModalIsOpen(!modalIsOpen);
  }

  function toggleModalConfirm() {
    setModalIsOpenConfirm(!modalIsOpenConfirm);
  }

  function getGameIdFromGamePath(gamePath) {
    const result = campDados.filter((item) => item.gamePath === gamePath);

    return result[0].gameId;
  }

  function getSquadromGamePath(gamePath) {
    const result = campDados.filter((item) => item.gamePath === gamePath);

    return result[0].gameId;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetParticipantes();
    handleGetEnrollmentsCount();
    // eslint-disable-next-line
  }, []);

  function handleGetEnrollmentsCount() {
    let gameId = getGameIdFromGamePath(pathCampeonato);
    let squad = getSquadromGamePath(pathCampeonato);
    if (squad === 'Solo') {
      axios
        .get(`https://ostem.gogamers.pro/enrollments/count/${gameId}`, headers)
        .then((response) => {
          setCurrentUsers(response.data.data.count);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .get(`https://ostem.gogamers.pro/enrollments/countTeam/${gameId}`, headers)
        .then((response) => {
          setCurrentUsers(response.data.data.count);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function handleGetParticipantes() {
    let gameId = getGameIdFromGamePath(pathCampeonato);
    axios
      .get(`https://ostem.gogamers.pro/enrollments/ibyte/${gameId}`, headers)
      .then((response) => {
        setParticipantes(response.data.data.enrollments);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <ContainerCampeonato>
        <Header />
        <RedLine />

        {campDados.map((item) => {
          if (item.gamePath === pathCampeonato) {
            let dateFormated = new Date(item.date);
            let actualDate = new Date();
            dateFormated.setHours(16);

            const differenceInMs = dateFormated - actualDate;

            let daysLeft = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

            return (
              <div key={item.gameId}>
                <div
                  className="callCampeonato"
                  style={{ backgroundImage: `url(${ibyteBg})`, backgroundSize: 'cover' }}>
                  <div className="tituloCampeonato">{item.championshipName}</div>
                  {daysLeft >= 0 && (
                    <button
                      className="inscrever"
                      onClick={() => {
                        toggleModal();
                      }}>
                      Inscreva-se
                    </button>
                  )}
                  {daysLeft < 0 && (
                    <button className="inscreverDisable">Inscrições encerradas</button>
                  )}
                  <div className="tempoInscrever">
                    {daysLeft < 0 && (
                      <span>
                        {' '}
                        As inscrições foram <strong>encerradas</strong>
                      </span>
                    )}
                    {daysLeft > 0 && (
                      <span>
                        🕒 Inscrições encerram em <strong>{daysLeft.toFixed()} dias</strong>
                      </span>
                    )}
                    {daysLeft === 0 && (
                      <span>
                        🕒 Inscrições encerram <strong>hoje</strong>
                      </span>
                    )}
                  </div>
                </div>

                <div className="bar">
                  <div className="detalhes">
                    {callDetalhes && (
                      <div
                        className="details active"
                        onClick={() => {
                          setDetalhes(true);
                          setChaveamento(false);
                          setRegulamento(false);
                          setCallParticipantes(false);
                        }}>
                        DETALHES
                      </div>
                    )}
                    {!callDetalhes && (
                      <div
                        className="details"
                        onClick={() => {
                          setDetalhes(true);
                          setChaveamento(false);
                          setRegulamento(false);
                          setCallParticipantes(false);
                        }}>
                        DETALHES
                      </div>
                    )}
                  </div>


                  {item.gamePath === 'valorant-ibyte' ? (
                    <div>
                      <div>
                        {callRegulamento && (
                          <div
                            className="regulamento active"
                            onClick={() => {
                              setDetalhes(false);
                              setChaveamento(false);
                              setRegulamento(true);
                              setCallParticipantes(false);
                            }}>
                            REGULAMENTO
                          </div>
                        )}
                      </div>
                      <div>
                        {!callRegulamento && (
                          <div
                            className="regulamento"
                            onClick={() => {
                              setRegulamento(true);
                              setDetalhes(false);
                              setChaveamento(false);
                              setCallParticipantes(false);
                            }}>
                            REGULAMENTO
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {item.gamePath === 'cs2-ibyte' ? (
                    <div>
                      <div>
                        {callRegulamento && (
                          <div
                            className="regulamento active"
                            onClick={() => {
                              setDetalhes(false);
                              setChaveamento(false);
                              setRegulamento(true);
                              setCallParticipantes(false);
                            }}>
                            REGULAMENTO
                          </div>
                        )}
                      </div>
                      <div>
                        {!callRegulamento && (
                          <div
                            className="regulamento"
                            onClick={() => {
                              setRegulamento(true);
                              setDetalhes(false);
                              setChaveamento(false);
                              setCallParticipantes(false);
                            }}>
                            REGULAMENTO
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {item.gamePath === 'lol-ibyte' ? (
                    <div>
                      <div>
                        {callRegulamento && (
                          <div
                            className="regulamento active"
                            onClick={() => {
                              setDetalhes(false);
                              setChaveamento(false);
                              setRegulamento(true);
                              setCallParticipantes(false);
                            }}>
                            REGULAMENTO
                          </div>
                        )}
                      </div>
                      <div>
                        {!callRegulamento && (
                          <div
                            className="regulamento"
                            onClick={() => {
                              setRegulamento(true);
                              setDetalhes(false);
                              setChaveamento(false);
                              setCallParticipantes(false);
                            }}>
                            REGULAMENTO
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  <div className="chameamento">
                    {callChaveamento && (
                      <div
                        className="chaveamento active"
                        onClick={() => {
                          setDetalhes(false);
                          setChaveamento(true);
                          setCallParticipantes(false);
                          setRegulamento(false);
                        }}>
                        CHAVEAMENTO
                      </div>
                    )}
                    {!callChaveamento && (
                      <div
                        className="chaveamento"
                        onClick={() => {
                          setDetalhes(false);
                          setChaveamento(true);
                          setCallParticipantes(false);
                          setRegulamento(false);
                        }}>
                        CHAVEAMENTO
                      </div>
                    )}
                  </div>

                  <div className="participantes">
                    {callParticipantes && (
                      <div
                        className="participantes active"
                        onClick={() => {
                          setDetalhes(false);
                          setChaveamento(false);
                          setCallParticipantes(true);
                          setRegulamento(false);
                        }}>
                        PARTICIPANTES
                      </div>
                    )}
                    {!callParticipantes && (
                      <div
                        className="participantes"
                        onClick={() => {
                          setDetalhes(false);
                          setChaveamento(false);
                          setCallParticipantes(true);
                          setRegulamento(false);
                        }}>
                        PARTICIPANTES
                      </div>
                    )}
                  </div>
                </div>

                {callDetalhes
                  ? InfoCampeonato(
                    item.date,
                    item.game,
                    currentSubs,
                    item.maxUsers,
                    item.squad,
                    item.inforCampeonato,
                    item.Formato,
                    item.awardDescription,
                    item.requirements,
                    item.timeline,
                    item.rules,
                    item.observations,
                    item.award,
                    item.gameId,
                  )
                  : null}
                <br></br>
                {
                  callRegulamento ? <div>
                    {<div>{item.gamePath === 'valorant-ibyte' ? <RegulamentoValorant /> : null}</div>}
                    {<div>{item.gamePath === 'cs2-ibyte' ? <RegulamentoCS /> : null}</div>}
                    {<div>{item.gamePath === 'cs2-ibyte' ? <RegulamentoLol /> : null}</div>}
                  </div> : null
                }

                {callChaveamento ? (

                  <div>
                    {item.result && (
                      <iframe
                        title="chaveamento"
                        src={item.result}
                        width="100%"
                        height="1000px"
                        frameBorder="0"
                        scrolling="auto"
                        allowtransparency="true"></iframe>
                    )}
                    {!item.result && (
                      <h2 className="chaveamento-waring">
                        Assim que o campeonato começar você poderá ver o chaveamento aqui
                      </h2>
                    )}
                  </div>
                ) : null}
                {callParticipantes ? (
                  <div>
                    <div className="CardParticipantesContent">
                      {participantes.map((participante, index) => {
                        return (
                          <div key={index}>
                            <div className="cardParticipantes">
                              <div className="iconCardParticipantes">
                                <BiUserCircle size={30} />
                              </div>
                              <p className="textParticipantes">{participante.name}</p>
                              <h5>@{participante.nickname}</h5>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          }
          return false;
        })}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          contentLabel="Modal de cards"
          className="modal"
          overlayClassName="modal-overlay">
          <FormContainer
            gameList={campDados.filter((item) => item.gamePath.includes(pathCampeonato))}
            openComfirmModal={toggleModalConfirm}
            fecharModal={toggleModal}
          />
        </Modal>

        <Modal
          isOpen={modalIsOpenConfirm}
          onRequestClose={toggleModalConfirm}
          contentLabel="Modal de cadastro concluído"
          className="modal"
          overlayClassName="modal-overlay">
          <ConfirmContainer fecharModal={toggleModalConfirm} />
        </Modal>
        <Rodape />
      </ContainerCampeonato>
    </>
  );
}
