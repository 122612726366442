import styled from 'styled-components';
import { device } from '../../utils';

export const ButtonCloseModal = styled.div`
display: flex;
align-items: center;
justify-content: center;
  button{
    padding: 15px 55px 15px 55px;
    background: #E94560 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #E94560;
    opacity: 1;
    text-align: center;
    font: normal normal normal 15px/20px Poppins;
    color: #FFFFFF;
    border: 0px;
    border-radius: 5px;
    margin: 30px 0 20px 0;
    cursor:pointer;
  }
`;
export const CloseButtonContainer = styled.div`
  width: 100%;
  padding: 5px 5px 0px 0px;
  text-align: end;

  .closeButtonIcon{
    background: transparent;
    border: none;
    cursor: pointer;
    color: white;
  }
`;

export const CheckIconModal = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContatoImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ContatoImg = styled.img`
  width: 40px;
  cursor : pointer;

  @media ${device.desktopSmall} {
    width: 50px;
  }
`;

export const ContatoLink = styled.a``;

export const TextModalConfirm = styled.div`
  margin: 25px auto 25px auto;
  color: white;
  text-align: center;
  padding-inline: 20px;
`;


