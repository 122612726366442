import HomePage from './pages/homePage/HomePage'
import Campeonato from 'pages/campeonatos/Campeonato';
import { FiAlertTriangle, FiAlertCircle } from "react-icons/fi";
import CampeonatoEncerrados from 'pages/campeonatos/CampeonatoEncerrados';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider, positions } from 'react-alert';
import ReactGA from "react-ga4";
import Termos from 'pages/termos/Termos';
import Politica from 'pages/politica/Politica';
import Regras from 'pages/regras/Regras'
import './styles/alert.css'
import { IoClose } from 'react-icons/io5';
import { BsCheck2Circle } from "react-icons/bs";

const TRACKING_ID = "G-5JYJ2FPTLV";
ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Ibyte" });

const AlertTemplate = ({ style, options, message, close }) => (
  <div className={`alerts ${options.type}`} style={style}>
    {options.type === 'info' && <FiAlertCircle size={20} className='iconAlert' />}
    {options.type === 'error' && <FiAlertTriangle size={20} className='iconAlert' />}
    {options.type === 'success' && <BsCheck2Circle size={20} className='iconAlert' />}
    <div style={{ marginInline: 20 }}>
      {message}
    </div>
    <IoClose className=' iconAlert closeAlert' onClick={close} ></IoClose>
  </div >
);



const options = {
  position: positions.TOP_CENTER,
  timeout: 50000000,
};


function App() {
  return (
    <Provider template={AlertTemplate} {...options}>
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/campeonato/:pathCampeonato" element={<Campeonato />} />
          <Route path="/campeonato-encerrados/:idCampeonato" element={<CampeonatoEncerrados />} />
          <Route path="/termos-de-uso" element={<Termos />} />
          <Route path="/politica-de-privacidade" element={<Politica />} />
          <Route path="/regras-de-conduta" element={<Regras />} />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
    </Provider>
  );
}

export default App;
