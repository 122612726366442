import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import InputMask from 'react-input-mask';
import ReactLoading from 'react-loading';
// import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { useAlert } from 'react-alert';
import {
  ButtomContainer,
  ButtomModal,
  ButtomEnviar,
  Form,
  RegisterForm,
  FormInput,
  FormLabel,
  RegisterInputCheckbox,
  RegisterCheckboxLabel,
  RegisterCheckboxWrapper,
  FormMessageError,
  // option
} from './style';

function FormContainer({ openComfirmModal, fecharModal, gameList }) {
  const [isLoading, setIsLoading] = useState(false);
  // const [captcha, setCaptcha] = useState('');
  const [cookie] = useCookies(['utm_source']);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const alert = useAlert();
  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  };

  async function sendDataToApi(enrollment) {
    let utmSource = cookie.utm_source?.toString();
    gameList.forEach((item) => {
      const data = JSON.stringify({
        gameId: item.gameId,
        gameName: item.gameName,
        name: enrollment.name.trim(),
        birthdate: enrollment.date,
        email: enrollment.email.trim(),
        whatsapp: enrollment.whatsapp.replace(/\D/g, '').trim(),
        teamName: enrollment.teamName,
        nickname: enrollment.nick.trim(),
        game: enrollment.game,
        utm_source: utmSource,
        instagram: enrollment.instagram,
        company: 'ibyte',
      });

      
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/enrollments/create`, data, { headers })
        .then((response) => {
          setIsLoading(false);
          fecharModal();
          openComfirmModal();
        })
        .catch((error) => {
          setIsLoading(false);
          if (error?.response === undefined) {
            alert.error('Erro desconhecido por favor entrar em contato como suporte!');
            return;
          }
          let errorStatusCode = error?.response?.status;
          errorStatusCode === 409
            ? alert.error('Já existe um cadastro em nosso sistema com esses dados!')
            : alert.error('Preencha todos os campos para realizar o cadastro!');
        });
    });
  }

  const onSubmit = (enrollment) => {
    if (!enrollment.acceptTerms) {
      alert.error('Aceite os termos e/ou preencha o Captcha para proseguir com sua inscrição!!');
    } else {
      setIsLoading(true);
      sendDataToApi(enrollment);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };

  function validateDdd(number) {
    const phoneNumber = number.replace(/\D/g, '');
    const ddd = parseInt(phoneNumber.substr(-20, 2));
    if (isNaN(ddd)) {
      return 'Insira apenas números para o DDD';
    }
    if (ddd < 11 || ddd > 99) {
      return 'Insira um DDD correto (entre 11 e 99)';
    }
    return true;
  }

  return (
    <Form>
      <RegisterForm>
        <ButtomContainer>
          <ButtomModal className="buttomModal" onClick={fecharModal}>
            <IoClose color="white" size={25} className="closeModalButtom" />
          </ButtomModal>
        </ButtomContainer>
        <FormLabel className="forLabelName" htmlFor="registerName">
          Nome
        </FormLabel>
        <FormInput
          id="registerName"
          type="text"
          placeholder="Digite seu nome"
          {...register('name', { required: true })}
        />
        {errors.name && <FormMessageError>Preencha os campos com dados válidos</FormMessageError>}
        <FormLabel htmlFor="registerData">Data de Nascimento</FormLabel>
        <FormInput
          id="registerData"
          type="date"
          placeholder="Sua data de nascimento"
          {...register('date', { required: true })}
        />
        {errors.date && <FormMessageError>Preencha os campos com dados válidos</FormMessageError>}

        <FormLabel htmlFor="registerEmail">E-mail</FormLabel>
        <FormInput
          id="registerEmail"
          type="text"
          placeholder="Seu email"
          {...register('email', { required: true, pattern: /[\w-.]+@([\w-]+.)+[\w-]{2,4}/ })}
        />
        {errors.email && <FormMessageError>Preencha os campos com dados válidos</FormMessageError>}

        <FormLabel htmlFor="registerWhatsapp">Whatsapp</FormLabel>
        <InputMask
          className="formInput"
          id="registerWhatsapp"
          type="tel"
          mask="(99)99999-9999"
          placeholder="Seu Whatsapp"
          {...register('whatsapp', {
            validate: (value) => validateDdd(value),
            required: true,
            minLength: 14,
            maxLength: 14,
          })}
        />
        {errors.whatsapp && (
          <FormMessageError>
            {errors.whatsapp.message || 'Preencha um número de telefone válido'}
          </FormMessageError>
        )}

        {gameList[0].squad !== 'Solo' ? (
          <>
            <FormLabel htmlFor="registerTime">Nome do time</FormLabel>
            <FormInput
              id="registerTime"
              type="text"
              placeholder="Digite o nome do seu time!"
              {...register('teamName', { required: true })}
            />
            {errors.teamName && (
              <FormMessageError>Preencha os campos com dados válidos</FormMessageError>
            )}
          </>
        ) : null}

        <FormLabel htmlFor="registerNick">Nick</FormLabel>
        <FormInput
          id="registerNick"
          type="text"
          placeholder="Seu apelido"
          {...register('nick', { required: true })}
        />
        {errors.nick && <FormMessageError>Preencha os campos com dados válidos</FormMessageError>}

        {/* <FormLabel htmlFor="registerNameRes">Nome do responsável</FormLabel>
        <FormInput
          id="registerNameRes"
          type="text"
          placeholder="Nome do seu responsável"
          {...register('teamName', { required: true })}
        /> */}

        {gameList[0].squad !== 'Solo' ? (
          <>
            <FormLabel htmlFor="registerInstagram">Instagram Time</FormLabel>
            <FormInput
              id="registerInstagram"
              type="text"
              placeholder="Instagram Time"
              {...register('instagram', { required: false })}
            />
          </>
        ) : null}

        {/* <FormLabel htmlFor="registerCidade">Cidade</FormLabel>
        <FormSelect
          id="registerCidade"
          type="text"
          placeholder="Sua cidade"
          {...register("city", { required: true })}
        >
          <option value="" disabled selected>Sua cidade</option>
          {cities.map((city) => {
            return (
              <option value={city.value}>{city.label}</option>
            )
          })}
        </FormSelect>
        {errors.city && <FormMessageError>Preencha os campos com dados válidos</FormMessageError>} */}

        {/* <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
          theme='light'
          onChange={setCaptcha}
        /> */}

        <RegisterCheckboxWrapper>
          <RegisterInputCheckbox id="registerAccept" type="checkbox" {...register('acceptTerms')} />
          <FormLabel htmlFor="registerAccept">
            Ao selecionar você concorda em compartilhar as suas informações com a Go Gamers e
            parceiros{' '}
            <RegisterCheckboxLabel href="/termos-de-uso" target="_blank">
              {' '}
              Termos de Uso.
            </RegisterCheckboxLabel>
          </FormLabel>
        </RegisterCheckboxWrapper>

        <ButtomEnviar disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          {isLoading ? (
            <ReactLoading type={'spin'} color={'#ffffff'} height={20} width={20} />
          ) : (
            <p>Enviar</p>
          )}
        </ButtomEnviar>
      </RegisterForm>
    </Form>
  );
}

export default FormContainer;
