import React from 'react';

import InstagramLogo from '../../../../assets/ativos/InstagramWhite.svg';
// import WhatsAppLogo from '../../../../assets/ativos/WhatsAppWhite.svg';
import TwitchLogo from '../../../../assets/ativos/TwitchWhite.svg';
import DiscordLogo from '../../../../assets/ativos/DiscordWhite.svg';
import { INSTAGRAM_URL, TWITCH_URL, DISCORD_URL } from '../../../../utils/const.js';
import { SideBarContainer, SideBarButton } from './styles.js';

const SideBar = () => {
  return (
    <SideBarContainer>
      <SideBarButton href={INSTAGRAM_URL} target="_blank " rel="noopener noreferrer">
        <img src={InstagramLogo} alt="Instagram" />
      </SideBarButton>
      {/* <SideBarButton href={WHATSAPP_URL} target="_blank " rel="noopener noreferrer">
        <img src={WhatsAppLogo} alt="Whatsapp" />
      </SideBarButton> */}
      <SideBarButton href={TWITCH_URL} target="_blank " rel="noopener noreferrer">
        <img src={TwitchLogo} alt="Twitch" />
      </SideBarButton>
      <SideBarButton href={DISCORD_URL} target="_blank " rel="noopener noreferrer">
        <img src={DiscordLogo} alt="Discord" />
      </SideBarButton>
    </SideBarContainer>
  );
};

export default SideBar;
