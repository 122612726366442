import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/ativos/progamers.png';

import { LeftContainer, HeaderLogo } from './styles';

const HeaderLeft = (props) => {
  return (
    <LeftContainer>
      <Link to={`/`} style={{ textDecoration: 'none' }}>
        <HeaderLogo src={Logo} alt="GoGamers" />
      </Link>
    </LeftContainer>
  );
};

export default HeaderLeft;
