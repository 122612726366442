import styled from 'styled-components';
import { device } from 'utils';

export const TermosContainer = styled.div`

  padding: 0 0 0 0;

  color: #FFFF;
  list-style-type: upper-roman;
  h2{
    border-top: 2px solid #E94560 
  }
  @media ${device.desktopSmall} {
    padding: 0 110px 0 110px;
    color: #FFFF;
    list-style-type: upper-roman;
    h2{
      border-top: 2px solid #E94560 
    }
  }
`;

export const TermosList = styled.div`
  padding: 2px;
  color: #FFFF;
  list-style-type: upper-roman;
`;

export const HeaderLogo = styled.img`
  width: 9rem;
  cursor: pointer;

  @media ${device.desktopSmall} {
    width: 12rem;
    margin: 3rem 0 3rem 45rem;
  }
`;