import { RegulamentoContainer } from "./styled";

export default function RegulamentoValorant() {
  return (
    <RegulamentoContainer>
      <h2>Arena ibyte Gamer - Campeonato de Valorant - Regulamento</h2>
      <br />
      <h2>1 - Definições</h2>
      <h3> 1.1 Abrangência</h3>
      <p>As regras mencionadas neste livro de regras serão aplicadas exclusivamente na competição onde ele for utilizado.</p>
      <br />
      <h3>1.2 Termo de Concordância</h3>
      <p>Os jogadores participantes devem estar de acordo com as regras descritas neste documento. Qualquer objeção contra qualquer parâmetro deste documento, será desconsiderado.</p>
      <br />
      <h3>1.3 As Alterações das Regras</h3>
      <p>A Administração da ProGamers se reserva no direito de alterar ou remover as regras, sem aviso prévio em casos de emergência ou situações que possam atrapalhar o bom andamento do evento. As novas regras serão publicadas neste livro com suas datas de atualização. Será informada a atualização no Discord Oficial ou na página do evento no site da ProGamers.</p>
      <br />
      <h3>1.4 Administração do Torneio</h3>
      <p>Os Administradores do Torneio serão os responsáveis pelo entendimento e aplicação das regras. No caso de eventuais erros de aplicação das regras por erros humanos (Administradores), nos reservamos ao direito de corrigir, seja revertendo resultados ou aplicando a punição adequada mesmo após a data da ocorrência para manter o padrão do evento para todos os participantes.</p>
      <br />
      <h3>1.5 Equipes e Jogadores</h3>
      <p>Todo jogador participante de uma equipe deve estar obrigatoriamente cadastrado na plataforma ProGamers e fornecer dados verdadeiros no momento do cadastro. Preencher dados falsos poderá acarretar punições para o jogador e sua equipe, variando desde advertências verbais ou desclassificação da equipe no torneio.</p>
      <br />
      <h3>1.6 Fuso horário</h3>
      <p>O site da ProGamers exibe os horários da partida de acordo com o fuso horário de Brasília.</p>
      <br />
      <h2>2 - Geral</h2>
      <h3>2.1 Contato com o Administrador do Torneio</h3>
      <br />
      <p>Os participantes podem entrar em contato com os Administradores do torneio por meio da área dos chats no Discord Oficial (<a href="https://discord.com/invite/XHFUSJ2NaK">https://discord.com/invite/XHFUSJ2NaK</a>). Ao solicitar ajuda, informe o nome de sua equipe e descreva claramente o motivo da solicitação.</p>
      <br />
      <h4>2.1.1 Contato com o Administrador via Plataforma</h4>
      <p>Durante o andamento do campeonato, cada partida poderá comunicar com a Administração do Campeonato na área suporte, sendo exclusivo para solução de problemas e dúvidas relacionadas as partidas.</p>
      <br />
      <h4>2.1.2 Comunicação entre Participantes</h4>
      <p>A comunicação entre as equipes poderá ser realizada conforme a preferência de ambas as equipes, seja por chat da plataforma ProGamers, chat do jogo ou pelo Discord. Caso ambas as equipes não entrem em acordo, a comunicação deverá ser feita através do chat da plataforma ProGamers.</p>
      <br />
      <h3>2.2 Código de Conduta</h3>
      <p>Todos os participantes dos eventos da ProGamers concordam em se comportar de maneira adequada e respeitosa em relação aos outros. Ofensa a qualquer participante ou à ProGamers será avaliada pelo juiz e pode acarretar em uma advertência ou punição a equipe infratora. Em caso de reincidência, a equipe será desclassificada da competição.</p>
      <br />
      <p>Caso haja ofensa por parte de duas equipes, independente da ordem de início, o juiz decidirá se as equipes serão desclassificadas. A partida deverá acontecer até o final e o juiz decidirá a punição após o término da mesma.</p>
      <br />
      <p>A equipe deve ter uma Captura de Tela (Screenshot/Printscreen) das ofensas e informar no canal #Report do Discord Oficial solicitando a avaliação de um Administrador.</p>
      <br />
      <p>Qualquer ação antidesportiva ou comportamento inadequado poderá acarretar em punições verbais e/ou desclassificação da equipe. A interpretação das regras e grau de penalidades por mau comportamento dependem apenas da organização do campeonato, cujas decisões são finais. É importante notar que as penalidades não precisam seguir uma ordem.</p>
      <br />
      <h3>2.3 Conta do Jogo</h3>
      <p>Cada jogador deve ter sua conta de jogo em seu respectivo cadastro na ProGamers. Não é permitido que terceiros acessem a conta de outro jogador a fim de participar de uma partida oficial. Caso seja comprovado o ato, o jogador envolvido e a equipe que o utilizou, serão todos banidos por 90 dias e desclassificados de seus torneios atuais.</p>
      <br />
      <h4>2.3.1 Atualização de Informações no Perfil</h4>
      <p>É responsabilidade dos jogadores sempre manterem seus dados atualizados no cadastro da plataforma.</p>
      <br />
      <h4>2.3.2 Limite de Elo/Rank e Level</h4>
      <p>Não há nenhuma restrição referente ao ranque ou nível dos jogadores.</p>
      <br />
      <h3>2.4 Premiação</h3>
      <p>Premiações em produtos goldentec a serem escolhidos no site da ibyte, com envio do link aos organizadores da competição, serão enviadas individualmente por um representante da equipe vencedora.</p>
      <br />
      <p>Premiações somente são pagas em até 15 dias úteis.</p>
      <br />
      <h4>2.4.1 Nickname Incorreto</h4>
      <p>Os jogadores que não atualizarem seus nicknames antes do início da competição, e nem informar ao Administrador do Torneio sobre seu Nickname desatualizado, não estarão aptos para receber premiação.</p>
      <br />
      <h3>2.5 Número de Jogadores por Equipe</h3>
      <p>A plataforma da ProGamers permite até 7 usuários dentro da equipe, podendo ser de qualquer país ou nacionalidade.</p>
      <br />
      <p>Todas as equipes devem ter pelo menos 5 jogadores inscritos na plataforma ProGamers. As equipes que não estiverem com a lineup completa até 10 minutos antes do horário de início do campeonato, terão a inscrição cancelada.</p>
      <br />
      <h4>2.5.1 Substituição de Jogadores</h4>
      <p>Não há limites para substituições de jogadores inscritos, porém devem ocorrer antes de iniciarem a Seleção de Campeões. Para realizar a substituição basta que o jogador inscrito seja convidado para o saguão da partida, sendo necessário informar à Administração.</p>
      <br />
      <h3>2.6 Calendário e Duração dos Eventos</h3>
      <p>A organização tem o direito de alterar o início e o fim de eventos conforme fatores internos ou externos. A decisão será publicada na página do evento no site da ProGamers e no Discord Oficial.</p>
      <br />
      <h3>2.7 Check-in</h3>
      <p>As equipes inscritas em um campeonato são divididas devem realizar check-in no dia, confirmando presença, e só após o termino, será liberado a tabela, com os participantes presentes mediante ao check-in:</p>
      <br />
      <p>Todas as equipes inscritas precisam realizar o Check-In obrigatório antes do início do torneio. Sendo no mínimo 3 representantes no momento do check-in para que a equipe consiga realizar esta ação.</p>
      <br />
      <p>O prazo para realização do Check-In obrigatório, geralmente de 1h do horário divulgado da competição, podendo variar de acordo com cada campeonato, portanto, é importante conferir a informação direto na página de inscrição na plataforma da ProGamers</p>
      <br />
      <p>Após a finalização do prazo do Check-In obrigatório, as equipes que não realizarem o Check-In automaticamente perdem sua inscrição. As vagas abertas serão preenchidas, conforme a ordem de inscrição, pelas equipes que realizaram o Check-In obrigatório.</p>
      <br />
      <p>Caso alguma equipe tenha problemas na realização do Check-In obrigatório, é necessário que seja reportado para a Administração do torneio antes do término do prazo.</p>
      <br />
      <p>Equipes que estiverem irregulares conforme regra 2.5 após o término do Check-In obrigatório, independente se fizeram o Check-In ou não, terão sua inscrição cancelada.</p>
      <br />
      <h2>3 - Regras de jogo</h2>
      <h3>3.1 Formato da Competição</h3>
      <p>As partidas serão disputadas em um formato de chave de eliminação simples em partidas melhor-de-um (MD1).</p>
      <br />
      <h4>3.1.1 Modo Alternado Torneio</h4>
      <p>Em campeonatos no modo Alternado Torneio, haverá escolha e banimento de campeões.</p>
      <br />
      <h4>3.1.2 Modo Escolha às Cegas</h4>
      <p>Em campeonatos no modo Escolha às cegas, haverá escolha dos campeões às cegas.</p>
      <br />
      <h4>3.1.3 Modo Todos Aleatórios</h4>
      <p>Em campeonatos no modo Todos Aleatórios, será escolhido aleatoriamente um campeão para cada jogador.</p>
      <br />
      <h3>3.2 Escolha de Lado</h3>
      <p>Equipes do lado de cima da tabela devem ser a equipe azul (lado esquerdo da saguão do jogo). Caso alguma equipe não siga esta regra, um Administrador deve ser notificado por meio do canal #Report no Discord com Captura de Tela (Screenshot/Printscreen) do saguão antes do início da partida.</p>
      <br />
      <h3>3.3 Prazo para Comparecer a uma Partida</h3>
      <p>As equipes possuem até 15 minutos após o horário marcado na página da partida para pegar a chave do torneio e iniciar o jogo. Caso ultrapasse o prazo, um Administrador deve ser acionado via Discord para avaliar o motivo e se necessário, aplicar o W.O..</p>
      <br />
      <p>Em casos de problemas técnicos, a organização deve ser informada para que seja analisada a situação e verificar a possibilidade de estender o prazo para as equipes envolvidas em até 10 minutos.</p>
      <br />
      <h4>3.3.1. Atrasos Devido a Problemas no Convite/Código</h4>
      <p>Os jogadores que forem impedidos de entrar na sala devido a problema no código, ou por convite, devem entrar em contato com um Administrador através do Discord Oficial da competição para relatar o ocorrido. Além disso, devem apresentar uma Captura de Tela (Screenshot/PrintScreen) do problema em até 5 minutos após estabelecido o contato, dessa forma cabe ao Administrador avaliar o ocorrido.</p>
      <br />
      <h4>3.3.2. Report no Discord</h4>
      <p>Para a análise do pedido de  desclassificação, a equipe deverá apresentar uma Captura de Tela (Screenshot/PrintScreen) do momento que entrou no saguão e do momento que ultrapassou os 15 minutos de tolerância de espera no saguão da partida.</p>
      <br />
      <h3>3.4 Quantidade Mínima de Jogadores em uma Partida</h3>
      <p>Partidas podem ser iniciadas com menos de 10 jogadores no saguão desde que haja um mínimo de 5 membros em cada equipe. Não será permitido uma equipe prosseguir em uma partida com menos do que 5 jogadores.</p>
      <br />
      <h3>3.5 Partida Iniciada com menos Jogadores Intencionalmente</h3>
      <p>O primeiro jogador que entrar na chave de torneio será considerado “dono” do saguão e será responsável por iniciar a partida. Caso seja iniciado a partida intencionalmente antes das equipes estarem completos e regulares no saguão, as equipes devem quitar imediatamente durante a seleção de campeões e o fato deve ser reportado a um Administrador para que seja avaliado o ocorrido e se necessário, aplicar as devidas punições. Este ato será passível de advertência verbal e desclassificação do torneio.</p>
      <br />
      <h3>3.6 Expulsão de um Jogador do Saguão Intencionalmente</h3>
      <p>O primeiro jogador que entrar na chave de torneio será considerado “dono” do saguão e tem o poder de expulsar outros jogadores. Expulsar, neste caso, é o mesmo que um banimento e o jogador não terá mais acesso ao saguão do Código de Torneio em questão. Caso isso seja feito intencionalmente, as equipes devem quitar imediatamente e o fato deve ser reportado a um Administrador no canal #Reports no Discord Oficial da ProGamers para que seja avaliado o ocorrido e se necessário, aplicar as devidas punições. Este ato será passível de advertência verbal e desclassificação do torneio.</p>
      <br />
      <p>Para que a partida prossiga, é necessário a liberação de um novo Código de Torneio, que estará disponível na página de sua partida na plataforma da ProGamers.</p>
      <br />
      <h3>3.7 Uso do Pause</h3>
      <p>Os 30 minutos totais de pausa serão divididos entre: 10 minutos por equipe e 10 minutos da Administração. </p>
      <br />
      <p>Não é necessário informar à equipe adversária o motivo do pause, porém é obrigatório aguardar a confirmação de ambas equipes para despausar. Caso ultrapasse o prazo de uma equipe, o pause deve ser removido imediatamente independente de consentimento.</p>
      <br />
      <p>Se uma equipe precisar de mais tempo de pausa, deve ser realizada uma solicitação via Discord explicando o motivo. O prazo pode ser estendido utilizando os 10 minutos da Administração de acordo com a análise e decisão de um Administrador.</p>
      <br />
      <p>O pause não deve ser utilizado durante Teamfights e objetivos.</p>
      <br />
      <h3>3.8 Falhas Durante o Draft</h3>
      <p>Caso um ou mais jogadores tenham problemas técnicos durante a seleção de campeão de uma partida, a equipe deve cancelar imediatamente o início da partida clicando em “sair” no canto inferior direito e a equipe adversária deve ser informada do motivo do ocorrido.</p>
      <br />
      <p>Onde ocorrer de um jogador ser desconectado durante a seleção de campeão, terá que ser feito os mesmos banimentos e mesmas escolhas de campeões. Por fim, se o sistema tiver selecionado algum campeão aleatório, poderá ser feito um novo, desde que não tenha acontecido nenhuma escolha ou banimento após, podendo ser necessário apresentar uma Captura de Tela (Printscreen/Screenshot de Tela) da seleção de campeão e/ou problema ocorrido.</p>
      <br />
      <p>Onde ocorrer de um jogador ser desconectado durante a seleção de campeão, terá que ser feito os mesmos banimentos e mesmas escolhas de campeões. Por fim, se o sistema tiver selecionado algum campeão aleatório, poderá ser feito um novo, desde que não tenha acontecido nenhuma escolha ou banimento após, podendo ser necessário apresentar uma Captura de Tela (Printscreen/Screenshot de Tela) da seleção de campeão e/ou problema ocorrido.</p>
      <br />
      <h4>3.8.1 Falhas Durante o Draft de Modo Todos Aleatórios</h4>
      <p>Será necessário uma Captura de Tela (Printscreen/Screenshot de Tela) apresentando a composição de ambos os lados, além de uma Captura de Tela (Printscreen/Screenshot de Tela) apresentando o problema, para que seja refeito as mesmas escolhas, sob risco de desclassificação.</p>
      <br />
      <h4>3.8.2  Pick Coringa</h4>
      <p>O uso de pick coringa não é permitido.</p>
      <br />
      <h3>3.9 Cheating e Fraude</h3>
      <p>Caso alguma equipe suspeite de alguma trapaça, o report deve ser realizado através do Discord Oficial, seguindo o procedimento informado no canal #Reports. Também deve ser feita uma denúncia diretamente à RIOT para que ocorra uma investigação mais aprofundada.</p>
      <br />
      <p>Após uma partida ter sido finalizada, a equipe acusante tem até 5 minutos após o envio de resultados para reportar as provas para a análise da Administração do Torneio.</p>
      <br />
      <h3>3.10 Falha no Sistema, na Plataforma e/ou no(s) Servidor(es)</h3>
      <p>Caso ocorra falha no sistema de jogo ou servidores RIOT, as equipes deverão pausar a partida e aguardar normalização da situação, uma vez que a plataforma da ProGamers não pode atuar em servidores externos. Além disso, devem informar imediatamente a Administração, para que tenha ciência e, onde for necessário, atuar e oferecer suporte.</p>
      <br />
      <p>Caso ocorra falha na plataforma ProGamers, a Administração deverá ser notificada imediatamente através dos canais de comunicação disponíveis para avaliarmos a situação e explicar o procedimento correto.</p>
      <br />
      <h4>3.10.1 Desconexão Pré-Partida</h4>
      <p>Se houver algum problema durante o carregamento da partida e, um ou mais jogadores não conseguirem conectar antes do início da partida, a mesma deve ser pausada e a equipe adversária informada sobre o motivo do pause. Do mesmo modo, a Administração deve ser contatada imediatamente através do Discord, para ciência do problema e orientar os jogadores.</p>
      <br />
      <h4>3.10.2 Problemas Técnicos Durante a Partida</h4>
      <p>Se houver algum problema durante o andamento da partida e, um ou mais jogadores apresentarem problemas técnicos e forem desconectados durante a partida, a mesma deve ser pausada e a equipe adversária informada sobre o motivo do pause. Do mesmo modo, a Administração deve ser contatada imediatamente através do Discord, para ciência do problema e orientar os jogadores.</p>
      <br />
      <p>É necessário entender que, caso o(s) jogador(es) não retorne(m), será removido o pause e o jogo será continuado, respeitando o número mínimo de jogadores por partida.</p>
      <br />
      <h3>3.10.3 Problemas Externos</h3>
      <p>A ProGamers não se responsabiliza por qualquer tipo de problema externo que possa afetar uma equipe e/ou jogadores durante o andamento do evento. As equipes e seus jogadores são responsáveis por seus equipamentos e conexões.</p>
      <br />
      <h3>3.10.4 Remake na Partida</h3>
      <p>Um remake somente poderá ser autorizado pela Administração do Torneio, nas seguintes condições:</p>
      <ul>
        <li>A partida seja criada incorretamente (escolha de lado, mapa, modo de jogo, etc);</li>
        <li>Se houverem quedas no servidor do jogo que impeçam o prosseguimento da partida;</li>
        <li>Se constatado problema na Seleção de Campeão e/ou Tela de Carregamento;</li>
        <li>Caso ocorra Bugsplat;</li>
        <li>Caso o jogo não assuma o status de Jogo Salvo1.</li>
      </ul>
      <br />
      <p>1 - Jogo Salvo se refere a um jogo em que os jogadores tenham progredido a um ponto de interação relevante entre as duas equipes. Quando um jogo atinge o status de Jogo Salvo, o período em que remakes podem ocorrer se encerra e o jogo passa a ser considerado oficial a partir desse momento. Exemplo de condições que assumem o status de Jogo Salvo:</p>
      <br />
      <p> 1. Qualquer ataque ou habilidade que acerte as tropas, monstros da selva, estruturas ou campeões inimigos;</p>
      <p>2. Visão é estabelecida entre as duas equipes adversárias;</p>
      <p>3. Invadir, estabelecer visão ou usar habilidades de skillshot na selva adversária por qualquer time, o que inclui tanto sair do rio ou entrar no arbusto conectado à selva inimigo;</p>
      <p>4. O jogo atinge a marca de 2 minutos (00:02:00)</p>
      <br />
      <p>Sempre será necessário solicitar a autorização da Administração para realizar o remake, mesmo que ele preencha os requisitos de remake citados acima.</p>
      <br />
      <h3>3.11  Desistência da Equipe</h3>
      <p>Caso uma equipe desista do evento, a mesma deve informar a Administração através do canal de suporte no Discord Oficial.</p>
      <br />
      <h4>3.11.1 Ferramenta Recriação</h4>
      <p>Caso uma equipe utilize a ferramenta recriação, sem autorização da Administração do Torneio, será entendido que a mesma desistiu da partida e a mesma não será refeita.</p>
      <br />
      <h4>3.11.2 Ferramenta Surrender (Desistência)</h4>
      <p>Caso uma equipe utilize a ferramenta desistência, sem autorização da Administração do Torneio, será entendido que a mesma desistiu da partida e a mesma não será refeita.</p>
      <br />
      <h3>3.12  Coach no Servidor da Partida</h3>
      <p>Somente será permitida a presença de técnico/coach no saguão da partida, caso o mesmo esteja registrado na equipe.</p>
      <br />
      <h3>3.13 Espectadores</h3>
      <p>Não será permitida a presença de espectadores nas partidas sem permissão prévia da Administração do evento.</p>
      <br />
      <p>Não será permitida a presença de espectadores nas partidas sem permissão prévia da Administração do evento.</p>
      <br />
      <p>Serão permitidos nos servidores apenas Administradores do campeonato, jogadores, narradores e comentaristas previamente autorizados pela Administração do campeonato.</p>
      <br />
      <p>Caso alguma equipe se sinta prejudicada ou perceba alguma irregularidade, a mesma deverá reportar o problema imediatamente com as devidas provas e evidências usando a ferramenta de comunicação disponível, se comprovada a denúncia, o resultado será revertido em W.O. a favor da equipe prejudicada.</p>
      <br />
      <h3>3.14 Uso de Bugs e Glitches</h3>
      <p>O uso intencional de bugs, glitches ou erros do jogo será avaliado pela Administração do evento e a mesma decidirá entre advertência, punição ou desclassificação da equipe da competição.</p>
      <br />
      <h3>3.15 Restrições Adicionais</h3>
      <p>Restrições podem ser adicionadas a qualquer momento antes ou durante uma partida, caso existam bugs conhecidos em itens, campeões, runas, skins ou se por qualquer motivo for determinado pelo critério da Administração.</p>
      <br />
      <h3>3.16 Envío de Resultados</h3>
      <p>Os resultados das partidas são computados automaticamente quando a partida for iniciada através da sala criada por código de torneio. Contudo, em casos de problemas com o resultado automático, é dever do Capitão de cada equipe enviar uma Captura de Tela (Screenshot/Printscreen) do resultado da partida do confronto no Discord Oficial da competição até 5 minutos depois do final da partida.</p>
    </RegulamentoContainer>
  )
};