import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root{
    /* can change color */
    --hue: 350;
    /*hsl colors  */
    --layout-color: hsla(var(--hue), 79%, 59%, 1); // cor vermelha do layout
    /*common color*/
    --text-color: hsla(0, 0%, 100%, 1); //cor branca do texto
    /* Text font*/
    --body-font-family: 'Poppins', san-serif;

    /*margins*/
    --margin-left: 12rem;
    --margin-right: 12rem;
    --margin-top: 12rem;
    
    
  }

  *, body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    object-fit: cover;
    font-family: var(--body-font-family);
    // font-size: 6.25% ; //ao definir tamanhos utilizar REM no lugar de pixel (1rem = 1px)
  }
  
  body{
    background: hsla(221, 37%, 19%, 1) ;
  }

  .glow{ //glowing boxes
    box-shadow: 0rem 0rem 40rem var(--layout-color);
    background: var(--layout-color);
    color: var(--text-color) !important;
    border-radius: 1rem;
     
    text-align: center;
    font: normal normal normal 20px/30rem Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border: 0rem;

  }

  @media (min-width: 1200px) {
  .container {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    width: 100%
  }

  .title{
    text-align: center;
    font: normal normal bold 40rem Poppins;
    letter-spacing: 0rem;
    color: #E94560;
    text-transform: uppercase;
    opacity: 1;
  }

  button:hover{
    cursor:pointer;
  }

  .link:hover{
    cursor:pointer;
  }

  
}

`;

/* ================= MEDIA QUERIES ================= */
// extra large devices: 1200 >
// large devices: 1023
// large devices: 992 >
// medium devices: 727
// smallest device mobile: 320rem
