import Rodape from '../../components/rodape/Rodape'
import Header from '../../components/header'
import React, { useEffect } from 'react'
import Modal from 'react-modal';
import { ContainerCampeonato, RedLine } from './styled'
import ibyteBg from '../../assets/ativos/backgroundIbyte.png'
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import InfoCampeonato from './components/InfoCampeonato/InfoCampeonato'
import campDadosEncerrados from '../../data/campDadosEncerrados.json'
import '../homePage/components/areaCampeonatos/modal.css';
import Regulamento from './components/Regulamento/RegulamentoValorant';

export default function Campeonato({ championshipName, championshipImg, timeLeft, content, squad, currentUsers, maxUsers, date, game }) {

    let { idCampeonato } = useParams();
    let [callDetalhes, setDetalhes] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    let [callChaveamento, setChaveamento] = useState(false);
    let [callRegulamento, setRegulamento] = useState(false);

    Modal.setAppElement('#root');

    if (championshipImg == null) {
        championshipImg = ibyteBg;
    }

    function toggleModal() {
        setModalIsOpen(!modalIsOpen);
    }



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ContainerCampeonato>
                <Header />
                <RedLine />
                {
                    campDadosEncerrados.map((item) => {
                        if (item.gamePath === idCampeonato) {

                            let dateFormated = new Date(item.date);
                            let actualDate = new Date();
                            dateFormated.setHours(14);

                            const differenceInMs = dateFormated - actualDate;

                            let daysLeft = differenceInMs / (1000 * 60 * 60 * 24);

                            return (
                                <div key={item.gameId}>
                                    <div className="callCampeonato" style={{ backgroundImage: `url(${ibyteBg})` }}>
                                        <div className="tituloCampeonato">{item.championshipName}</div>
                                        {daysLeft > 0 && <button className="inscrever" onClick={() => { toggleModal() }}>Inscreva-se</button>}
                                        {daysLeft <= 0 && <button className="inscreverDisable">Inscrições encerradas</button>}
                                        <div className="tempoInscrever">
                                            {daysLeft <= 0 && <span> As inscrições foram <strong>encerradas</strong></span>}
                                            {daysLeft > 0 && <span>🕒 Inscrições encerram em <strong>{daysLeft.toFixed()} dias</strong></span>}
                                        </div>
                                    </div>

                                    <div className="bar">

                                        {callDetalhes &&
                                            <div className="details active" onClick={() => { setDetalhes(true); setChaveamento(false); setRegulamento(false) }}>DETALHES</div>
                                        }
                                        {!callDetalhes &&
                                            <div className="details" onClick={() => { setDetalhes(true); setChaveamento(false); setRegulamento(false) }}>DETALHES</div>
                                        }

                                        {callRegulamento &&
                                            <div className="regulamento active" onClick={() => { setDetalhes(false); setChaveamento(false); setRegulamento(true) }}>REGULAMENTO</div>
                                        }
                                        {!callRegulamento &&
                                            <div className="regulamento" onClick={() => { setDetalhes(false); setChaveamento(false); setRegulamento(true) }}>REGULAMENTO</div>
                                        }

                                        {callChaveamento &&
                                            <div className="chaveamento active" onClick={() => { setDetalhes(false); setChaveamento(true); setRegulamento(false) }}>CHAVEAMENTO</div>
                                        }
                                        {!callChaveamento &&
                                            <div className="chaveamento" onClick={() => { setDetalhes(false); setChaveamento(true); setRegulamento(false) }}>CHAVEAMENTO</div>
                                        }

                                    </div>
                                    {callDetalhes ? InfoCampeonato(item.date, item.game, item.currentUsers, item.maxUsers, item.squad, item.requirements, item.rules, item.observations) : null}
                                    <br></br>
                                    {callRegulamento ? <div>
                                        {item.result && <iframe title='regulamento' src={item.result} width="100%" height="1000px" frameBorder="0" scrolling="auto" allowtransparency="true"></iframe>}
                                        {!item.result && <Regulamento />}
                                    </div> : null}
                                    {callChaveamento ? <div>
                                        {item.result && <iframe title='chaveamento' src={item.result} width="100%" height="1000px" frameBorder="0" scrolling="auto" allowtransparency="true"></iframe>}
                                        {!item.result && <h2 className='chaveamento-waring'>Assim que o campeonato começar você poderá ver o chaveamento aqui</h2>}
                                    </div> : null}
                                </div>
                            )
                        }
                        return (false)
                    })
                }


                <Rodape />
            </ContainerCampeonato>
        </>
    )
}
