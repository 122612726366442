import React, { useState } from 'react';
import '../FAQ.css';
import { TiChevronRight } from "react-icons/ti";

function FAQItem(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={toggleAnswer}>
        <div className='faq-icon'>
          <h3>{props.question}</h3>
          {isOpen ? <TiChevronRight className='arrow-icon toggle-right' size={20} /> : <TiChevronRight className='arrow-icon toggle-down' size={20} />}
      </div>
      {isOpen ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
    </div>
      { isOpen && <div className="faq-answer">
      {props.answer.map(answer => (
    <div>
      <p>{answer}</p>
    </div>
  ))}
      </div> }
    </div >
  );
}

export default FAQItem;