import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width:320px;
  width:100%;

  max-height: 564px;
  height: 100%;

  border: 1px solid #fafafa22;
  box-shadow: 0px 15px 30px #0000007D;
  border-radius: 7px 7px 7px 7px;
  color: #FFFFFF;

  transition-duration:1s;
  cursor: pointer;
  
  &:hover {
    transition-duration: 0.8s;
    transform: scale(0.9);
  }
`;


export const ResultLink = styled.a`
  font-size: 10pt;
  letter-spacing: 0px;
  background: var(--layout-color);
  text-decoration: none;
  opacity: 1;
  color: #fff;
  text-align: center;
  padding: 2px 10px;

  box-shadow: 0rem 0rem 1rem var(--layout-color);
  border-radius: 4px;
  cursor: pointer;
`

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;


export const CardChampionshipImage = styled.img`
  border-radius: 7px 0px 0px 7px;
  width: 100%;
  height: 312px;
`;

export const CardGameImage = styled.img`
  position: absolute;
  width: 50px;
  border-radius: 4px;
  margin: 1rem;
  border: 2px solid white;
    height: 50px;
`;

export const DetailsContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index:996;
  width:100%;
  font-size: 14px;
  text-align: center;
  padding: 0.5rem 0;
  background-color:rgba(31, 43, 68, 0.8);
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1f2b44;
  height:100%;
`;

export const CardTitle = styled.p`
  height: 60px;
  font-size: 19px;
  padding: 1rem;
`;

export const CardFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const CardSmallInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 1rem;
`;

export const CardSmallInfoText = styled.span`
  font-size: 12pt;
`;
