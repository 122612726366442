import styled from 'styled-components';

export const ButtomContainer = styled.div`
  width:100%;
  padding-top: 10px;
  margin-bottom:-10px;
  text-align: end;
`;

export const FormMessageError = styled.span`
width: 100%;
margin-top: -8px;
margin-bottom: 10px;
text-align: left;
font-size: 12px;
color: #ff4245;
`

export const ButtomModal = styled.button`
  background:transparent;
  border:none;
  cursor:pointer;
`;

export const ButtomEnviar = styled.button`  
  width: 100px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--layout-color);
  color: var(--text-color);
  opacity: 1;
  text-align: center;
  border: 1px solid var(--layout-color);
  padding: 5px 15px 5px 15px;
  margin: 10px 0rem;
  cursor: pointer;
  box-shadow: 0rem 0rem 1rem var(--layout-color);
  border-radius: 4px;
  cursor:pointer;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 450px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;

export const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;

  .formInput {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: 1px solid var(--text-color);
    outline:none;
    margin-bottom: 10px;
    font-size: 12px;
    color: black;
  }
`;

export const FormSelect = styled.select`
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--text-color);
  outline:none;
  margin-bottom: 10px;
  font-size: 12px;
  color: black;
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--text-color);
  outline:none;
  margin-bottom: 10px;
  font-size: 12px;
  color: black;
`;

export var FormLabel = styled.label`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  text-align: left;
  width: 100%;
`;

export const RegisterInputCheckbox = styled.input`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
`;

export const RegisterCheckboxLabel = styled.a`
font-size:12px;
  text-transform: underline;
  color: var(--layout-color);
`;

export const RegisterCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;