import React from 'react';
import { useCookies } from 'react-cookie';

import {
    Container,
    Header,
    WaringMessage,
    ButtonsContainer,
    Button,
} from './styles';

const AvisoCookies = () => {
    const [cookies, setCookie] = useCookies(['cookies_is_accept']);

    function acceptCookies() {
        
        if (cookies.cookies_is_accept === undefined) {
            let expireDate = new Date();
            expireDate = new Date(
                expireDate.setDate(expireDate.getDate() + 365)
            );
            setCookie('cookies_is_accept', true, { expires: expireDate });
        }
    }

    function refuseCookies() {
        if (cookies.cookies_is_accept === undefined) {
            let expireDate = new Date();
            expireDate = new Date(
                expireDate.setDate(expireDate.getDate() + 365)
            );
            setCookie('cookies_is_accept', false, { expires: expireDate });
        }
    }

    return (
        <>
            {!cookies.cookies_is_accept &&
                <Container>
                    <Header>Aviso de Cookies</Header>
                    <WaringMessage>Este site usa cookies para melhorar sua experiência de navegação e personalizar o conteúdo que lhe é apresentado. Ao continuar a navegar neste site, você concorda com o uso de cookies.</WaringMessage>
                    <ButtonsContainer>
                        <Button onClick={() => acceptCookies()}>Aceitar</Button>
                        <Button onClick={() => refuseCookies()}>Recusar</Button>
                    </ButtonsContainer>
                </Container>
            }
        </>
    )
}

export default AvisoCookies;