import Contato from './contato/Contato';
import Logo from './logo/Logo';

import {
  RodapeContainer,
  RodapeMainContent,
  RodapeLogoContatoContainer,
  RodapeLinkMenusContainer,
  RodapeCopyrightContainer,
  RodapeCopyright,
} from './styles';

const Rodape = () => {
  return (
    <RodapeContainer>
      <RodapeMainContent>
        <RodapeLogoContatoContainer>
          <Logo />
          <Contato />
        </RodapeLogoContatoContainer>

        <RodapeLinkMenusContainer>
        </RodapeLinkMenusContainer>
      </RodapeMainContent>

      <RodapeCopyrightContainer>
        <RodapeCopyright>© All Rights Reserved | Designed By ProGamers</RodapeCopyright>
      </RodapeCopyrightContainer>
    </RodapeContainer>
  );
};

export default Rodape;
