import styled from 'styled-components';

import { device } from '../../../../utils';

export const SideBarContainer = styled.div`
  margin-left: 0rem;
  z-index:997;
  position: fixed;
  margin-top: 35vh;
`;

export const SideBarButton = styled.a`
  width: 3rem;
  height: 2.75rem;
  margin: 1rem 0;

  > img {
    width: 1.5rem;
    height: auto;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  background: #e94560;
  box-shadow: 0rem 0rem 1rem #e94560;
  border: 0;
  border-radius: 0px 6px 6px 0px;
  opacity: 1;
  cursor: pointer;

  transition-duration:1s;

  &:hover {
    transition-duration: 0.8s;
    transform: scale(1.1);
  }

  @media ${device.desktopSmall} {
    width: 4rem;
    height: 3.75rem;
    margin: 1rem 0;
    border-radius: 0px 6px 6px 0px;
    > img {
      width: 2rem;
      height: auto;
    }
  }

  @media (max-width:1024;)
`;
