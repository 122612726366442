import ImgLogo from '../../../assets/ativos/progamers.png';

import { LogoContainer, LogoImg } from './styles';

const Logo = () => {
  return (
    <LogoContainer>
      <LogoImg src={ImgLogo} alt="GoGamers logo" />
    </LogoContainer>
  );
};

export default Logo;
