import styled from 'styled-components';

import { device } from '../../../utils';

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 2rem;
`;

export const LogoImg = styled.img`
  width: 150px;

  @media ${device.desktopSmall} {
    width: 250px;
  }
`;
