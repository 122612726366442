import React from 'react';

import background from '../../../../assets/ativos/bannerSite2.png';
import Header from '../../../../components/header';
import arena from 'assets/ativos/arenaLogo.png';

import {
  Container,
  Title,
  BannerWrapper,
  Description,
  ActionWrapper,
  ImageArena,
} from './styles.js';

const BoasVindas = () => {
  return (
    <Container
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Header active="inicio" />

      <ImageArena src={arena}></ImageArena>

      <BannerWrapper>
        <Title>
          Arena Gamer ibyte & Lenovo - O campo de batalha dos apaixonados por competição!
        </Title>
        <Description>
          A ibyte e a Lenovo abrem suas portas para levar o clima das grandes competições de eSports
          para perto de você!
        </Description>
        <ActionWrapper></ActionWrapper>
      </BannerWrapper>
    </Container>
  );
};

export default BoasVindas;
