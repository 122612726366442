import { RegrasContainer, RegrasList } from './styled'

import Logo from 'assets/ativos/Logo 1.png';

import { HeaderLogo } from './styled';
import { Link } from "react-router-dom";

export default function Regras() {
  return (
    <RegrasContainer>
       
       <Link to={`/`} style={{ textDecoration: 'none' }}>
          <HeaderLogo src={Logo} alt="GoGamers" />
          </Link>
      
      <h1>Regras de Conduta</h1>
      <br></br>
      <RegrasList>
        <li>Ao realizar a denúncia ou report você concorda que a punição é decidida e aplicada pela administração da Go Gamers e não cabe ao jogador contestar esta decisão.</li>
        <br></br>
        <li>Não compartilhamos as provas do banimento.</li>
        <br></br>
        <li>As denúncias ou reports devem ser feitos em até 48 horas após o ocorrido.</li>
        <br></br>
        <li>IV. É importante frisar que os casos que não estão especificados neste tópico serão analisados separadamente pela equipe da Go Gamers e poderão ser aplicadas punições divergentes das descritas abaixo.</li>
        <br></br>
        <li>Você é responsável pela sua conta na plataforma Go Gamers. Nunca a empreste para ninguém.</li>
        </RegrasList>
      <br></br>

      <h2>INFRAÇÕES</h2>
      <br></br>
      <p>SÃO CONSIDERADAS INFRAÇÕES NA PLATAFORMA DA GO GAMERS, SENDO PARA TANTO ENTENDIDAS COMO CONDUTAS NÃO ACEITÁVEIS E ESTÃO SUJEITAS A PUNIÇÃO:</p>
      <br></br>

      <h2>DISCURSO DE ÓDIO</h2>
      <br></br>
      <p>Mensagem que incentive o ódio e a incitação a hostilidade ou violência contra uma pessoa ou grupo em virtude de raça, religião, nacionalidade, orientação sexual, gênero, condição física ou outra característica.</p>
      <br></br>
      <p>Punição: encerramento de conta.</p>
      <br></br>

      <h2>DENÚNCIA FALSA</h2>
      <br></br>
      <p>Envio de denúncia de fato improcedente, armado, simulado, falso ou inventado. Denúncia em situação induzida pelo denunciante para “armar” sobre o denunciado, levando-o a cometer má atitude.</p>
      <br></br>
      <p>Punição: Suspensão da conta por tempo a ser definido pela administração da plataforma.</p>
      <br></br>

      <h2>CONDUTA DE MÁ FÉ</h2>
      <br></br>
      <p>Se fazer passar por administrador/suporte do site e suas redes socias; inventar mentiras que afetam de maneira negativa a credibilidade da plataforma; tentar enganar e prejudicar outros jogadores com falsas dicas; incentivar/conclamar/ensinar outros usuários a burlar regras do jogo e da Go Gamers; Incentivar/conclamar outros usuários a fazer atitudes condenáveis deste livro de regras. Desconectar no meio da partida propositalmente; Utilizar qualquer tipo de bug de mapa e/ou do próprio timer, em qualquer modo de jogo; Utilizar a ferramenta de espectador como coach de forma indevida ou para tirar vantagem do time adversário; Utilizar de qualquer meio de comunicação na plataforma para divulgar campeonatos, outras plataformas, site de terceiros, scam e afins.</p>
      <br></br>
      <p>Punição: Suspensão da conta por tempo a ser definido pela administração da plataforma ou encerramento desta.</p>
      <br></br>

      <h2>ANTIJOGO</h2>
      <br></br>
      <p>Utilização intencional do tempo de jogo, vetos ou ready, até próximo do limite permitido visando desgastar o adversário, para forçar a desistência dele ou outra razão. Fugir do combate em round faca por muito tempo. Utilizar qualquer ferramenta disponível no jogo para informar onde o companheiro de equipe está, dando vantagem para a equipe adversária. Atitudes tóxicas que atrapalhem a jogabilidade (atirar no companheiro de equipe para atrapalhar a mira, ferir e cegar com granadas propositalmente, remover os pauses, roubar armas dropadas a outros jogadores). Prejudicar equipe jogando de propósito mal ou com descaso. Má comunicação e/ou falta de comunicação durante o jogo, se comunicar in-game de forma a atrapalhar os companheiros de time (uso exacerbado dos comandos de rádio) ou até mesmo não se comunicar durante a partida (mesmo que pelo say_team) resultará em punição.</p>
      <br></br>
      <p>Punição: Suspensão da conta por tempo a ser definido pela administração da plataforma.</p>
      <br></br>


      <h2>TEXTO OU IMAGENS OFENSIVOS NO PERFIL DA GO GAMERS</h2>
      <br></br>
      <p>Apelido (nick) que expressa atitude preconceituosa ou ofensa grave aos demais membros da comunidade e/ou à plataforma; Texto de ódio, incitando a violência ou ameaçando outros jogadores; Imagem imprópria (teor sexual, nudez), que incite ódio (Ex: Ku Kux Klan, Hitler, nazismo) ou xenofobia, pedofilia e uso de drogas.; Uso de imagens de terceiros sem a devida autorização.</p>
      <br></br>
      <p>Punição: Suspensão da conta por tempo a ser definido pela administração da plataforma.</p>
      <br></br>

      <h2>USO DE CHEAT</h2>
      <br></br>
      <p>Utilizar quaisquer programas de terceiros não autorizados, incluindo, mas não limitado a, "mods", "hacks", "cheats" "scripts," "bots", "trainers" e programas de automação que interajam com o jogo de qualquer forma, para qualquer fim. Utilizar quaisquer programas de terceiros não autorizados que interceptem, emulem ou redirecionem qualquer comunicação entre o jogo e os servidores da Go Gamers e quaisquer programas de terceiros não autorizados que coletem informações sobre o jogo.</p>
      <br></br>
      <p>Punição: encerramento de conta.</p>
      <br></br>
    </RegrasContainer >
  )
};