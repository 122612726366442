import React from 'react';

import HeaderLeft from './Left';
import { HeaderContainer } from './styles';

const Header = (props) => {
  return (
    <HeaderContainer className="container">
      <HeaderLeft active={props.active}/>
    </HeaderContainer>
  );
};

export default Header;
